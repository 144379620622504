.btn {
  border        : 0;
  border-radius : 0;
  padding       : 0 25px;
  background    : transparent;
  text-align    : center;
  line-height   : 40px;
  text-transform: uppercase;
  position      : relative;
  overflow      : hidden;
  color         : #fff;
  transition    : all 0.2s ease;
}

.btn span {
  margin-bottom: 0 !important;
  position     : relative;
  z-index      : 10;
  display      : inline-block;
  transition   : all 0.25s ease;
}

.btn i {
  margin-left: 10px;
}

.btn.btn-green {
  /* background-color: #3275BB; */
  background-color: #3275bb;
}

.btn.btn-green:after {
  position        : absolute;
  content         : '';
  right           : 100%;
  background-color: #242c42;
  width           : 100%;
  height          : 100%;
  top             : 0;
}

.btn.btn-green:hover {
  color: #fff;
}

.btn.btn-green:hover:after {
  right: 0;
}

.btn.btn-green:focus span {
  color: #fff;
}

.btn.btn-green-2 {
  line-height     : 40px;
  background-color: transparent;
}

.btn.btn-green-2:before {
  position: absolute;
  content : '';
  border  : 2px solid #fff;
  width   : 100%;
  height  : 100%;
  left    : 0;
}

.btn.btn-green-2:after {
  position           : absolute;
  content            : '';
  /* background-color: #3275BB; */
  background-color   : #3275bb;
  width              : 100%;
  height             : 100%;
  top                : 0;
  right              : 0;
}

.btn.btn-green-2:hover {
  color: #fff;
}

.btn.btn-green-2:hover:after {
  right: -100%;
}

.btn.btn-green-2:focus span {
  color: #fff;
}

.btn.btn-green-3 {
  line-height     : 40px;
  background-color: transparent;
  color           : #fff;
}

.btn.btn-green-3:before {
  position : absolute;
  content  : '';
  /* border: 2px solid #3275BB; */
  border   : 2px solid #3275bb;
  width    : 100%;
  height   : 100%;
  top      : 0;
  left     : 0;
}

.btn.btn-green-3:after {
  position           : absolute;
  content            : '';
  /* background-color: #3275BB; */
  background-color   : #3275bb;
  width              : 100%;
  height             : 100%;
  top                : 0;
  right              : 0;
}

.btn.btn-green-3:hover {
  /* color: #3275BB; */
  color: #3275bb;
}

.btn.btn-green-3:hover:after {
  right: -100%;
}

.btn.btn-green-3:focus span {
  /* color: #3275BB; */
  color: #3275bb;
}

.btn.btn-transition {
  line-height     : 40px;
  background-color: transparent;
  padding         : 0 27px;
}

.btn.btn-transition:before {
  position: absolute;
  content : '';
  border  : 2px solid #fff;
  width   : 100%;
  height  : 100%;
  left    : 0;
}

.btn.btn-transition:after {
  position        : absolute;
  content         : '';
  right           : 100%;
  background-color: #3275bb;
  width           : 100%;
  height          : 100%;
  top             : 0;
}

.btn.btn-transition:hover {
  color: #fff;
}

.btn.btn-transition:hover:after {
  right: 0;
}

.btn.btn-transition:focus span {
  color: #fff;
}

.btn.btn-transition-2 {
  line-height     : 40px;
  background-color: transparent;
  color           : #242c42;
  padding         : 0 27px;
}

.btn.btn-transition-2:before {
  position: absolute;
  content : '';
  border  : 2px solid #242c42;
  width   : 100%;
  height  : 100%;
  left    : 0;
}

.btn.btn-transition-2:after {
  position        : absolute;
  content         : '';
  right           : 100%;
  background-color: #242c42;
  width           : 100%;
  height          : 100%;
  top             : 0;
}

.btn.btn-transition-2:hover span {
  color: #fff;
}

.btn.btn-transition-2:hover:after {
  right: 0;
}

.btn.btn-transition-2:focus span {
  color: #242c42;
}

.btn.btn-transition-2:focus:hover span {
  color: #fff;
}

.btn.btn-transition-3 {
  line-height     : 40px;
  background-color: transparent;
  padding         : 0 27px;
}

.btn.btn-transition-3:before {
  position: absolute;
  content : '';
  border  : 2px solid #fff;
  width   : 100%;
  height  : 100%;
  left    : 0;
}

.btn.btn-transition-3:after {
  position        : absolute;
  content         : '';
  right           : 100%;
  background-color: #fff;
  width           : 100%;
  height          : 100%;
  top             : 0;
}

.btn.btn-transition-3:hover {
  color: #3275bb;
}

.btn.btn-transition-3:hover:after {
  right: 0;
}

.btn.btn-transition-3:hover:before {
  border-left-color: #3275bb;
}

.btn.btn-transition-3:focus span {
  color: #3275bb;
}

.btn.btn-blue {
  background-color: #242c42;
}

.btn.btn-blue:after {
  position           : absolute;
  content            : '';
  right              : 100%;
  /* background-color: #fff; */
  width              : 100%;
  height             : 100%;
  top                : 0;
}

.btn.btn-blue:hover {
  /* color: #3275BB; */
  color: #3275bb;
}

.btn.btn-blue:hover:after {
  right: 0;
}

.btn.btn-blue:focus span {
  color: #3275bb;
}

.btn.btn-grey {
  background-color: #90a1ae;
  width           : 170px;
  line-height     : 40px;
}

.btn.btn-grey:after {
  position        : absolute;
  content         : '';
  right           : 100%;
  background-color: #3275bb;
  width           : 100%;
  height          : 100%;
  top             : 0;
}

.btn.btn-grey:hover {
  color: #fff;
}

.btn.btn-grey:hover:after {
  right: 0;
}

.btn.btn-grey:focus span {
  color: #fff;
}

.btn.btn-bold {
  font-weight   : bold;
  letter-spacing: 1px;
}

.btn.btn-404 {
  margin          : auto;
  width           : 200px;
  height          : 50px;
  background-color: #e9edf5;
  border          : none;
}

.btn.btn-404 span {
  font-weight: 900;
  font-size  : 24px;
  color      : #fff;
}

.btn.btn-404:after {
  position        : absolute;
  content         : '';
  right           : 100%;
  background-color: #3275bb;
  width           : 100%;
  height          : 100%;
  top             : 0;
}

.btn.btn-404:hover {
  color: #fff;
}

.btn.btn-404:hover:after {
  right: 0;
}

.btn.btn-404:focus span {
  color: #fff;
}

.btn.btn-green:after,
.btn.btn-green-2:after,
.btn.btn-green-3:after,
.btn.btn-transition:after,
.btn.btn-transition-2:after,
.btn.btn-transition-3:after,
.btn.btn-blue:after,
.btn.btn-grey:after,
.btn.btn-404:after {
  transition: all 0.5s ease;
}

.group-button {
  display   : block;
  margin    : 0 auto;
  width     : 100%;
  text-align: center;
}

.group-button button:first-child {
  margin-right: 16px;
}

.btn-list-grid li {
  display: inline-block;
}

.btn-list-grid li.active button {
  background: #49575f;
  color     : #fff;
}

.btn-list-grid li.active:hover button {
  background: #49575f;
}

.btn-list-grid li:hover button {
  background: rgba(73, 87, 95, 0.5);
  color     : #fff;
}

.btn-list-grid .btn-list,
.btn-list-grid .btn-grid {
  display   : inline-block;
  border    : none;
  background: transparent;
  transition: all 0.2s ease-in-out;
}

.btn-list-grid .btn-grid {
  margin-right: 10px;
}

.title-2 {
  font-size     : 30px;
  font-weight   : 700;
  margin        : 0;
  text-transform: uppercase;
  margin-bottom : 25px;
}

.background-opacity {
  position: relative;
}

.background-opacity:before {
  position        : absolute;
  content         : '';
  top             : 0;
  right           : 0;
  bottom          : 0;
  left            : 0;
  background-color: rgba(10, 15, 28, 0.7);
}

.background-opacity-2 {
  position: relative;
}

.background-opacity-2:before {
  position        : absolute;
  content         : '';
  top             : 0;
  right           : 0;
  bottom          : 0;
  left            : 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.section-padding {
  padding: 100px 0;
}

.group-title-index {
  text-align   : center;
  margin-bottom: 60px;
  position     : relative;
  z-index      : 2;
  color        : #49575f;
}

.group-title-index .top-title {
  text-transform: uppercase;
  margin        : 0 0 15px 0;
  font-weight   : 400;
  font-size     : 16px;
}

.group-title-index .center-title {
  font-size     : 30px;
  font-weight   : 900;
  margin        : 0 0 15px 0;
  text-transform: uppercase;
  line-height   : 30px;
}

.group-title-index .bottom-title {
  position: relative;
  display : inline-block;
}

.group-title-index .bottom-title .bottom-icon {
  margin   : 0 auto;
  font-size: 26px;
  /* color : #3275BB; */
  color    : #3275bb;
}

.group-title-index .bottom-title:after,
.group-title-index .bottom-title:before {
  position           : absolute;
  content            : '';
  width              : 22px;
  height             : 2px;
  /* background-color: #3275BB; */
  background-color   : #3275bb;
  top                : 50%;
  margin-top         : -5px;
}

.group-title-index .bottom-title:after {
  left: -32px;
}

.group-title-index .bottom-title:before {
  right: -32px;
}

.group-btn-slider .btn-prev,
.group-btn-slider .btn-next {
  position     : absolute;
  font-size    : 24px;
  border       : 2px solid;
  height       : 60px;
  width        : 60px;
  text-align   : center;
  line-height  : 53px;
  border-radius: 50%;
  opacity      : 0.3;
  top          : 50%;
  margin-top   : -25px;
  padding      : 0;
  transition   : all 0.3s linear;
  cursor       : pointer;
  z-index      : 10;
}

.group-btn-slider .btn-prev:hover,
.group-btn-slider .btn-next:hover {
  opacity: 1;
}

.group-btn-slider .btn-prev {
  left: 3%;
}

.group-btn-slider .btn-next {
  right: 3%;
}

.info .item {
  text-transform: capitalize;
  display       : inline-block;
}

.info .item a {
  color: #49575f;
}

.info .item a:hover {
  color: #3275bb;
}

.info .item:after {
  content: '|';
  margin : 0 5px;
  color  : #bfc9ce;
}

.info .item:last-child {
  margin: 0;
}

.info .item:last-child:after {
  display: none;
}

.info-more .item {
  display     : inline-block;
  margin-right: 12px;
  color       : #49575f;
}

.info-more .item:last-child {
  margin-right: 0;
}

.info-more .item i,
.info-more .item p {
  display      : inline-block;
  margin-bottom: 0;
}

.info-more .item i {
  margin-right: 5px;
}

.star-rating {
  position   : relative;
  display    : block;
  float      : left;
  line-height: 1;
  width      : 65px;
  height     : 1em;
  overflow   : hidden;
}

.star-rating:before {
  content    : '\f006\f006\f006\f006\f006';
  top        : 0;
  position   : absolute;
  left       : 0;
  font-family: FontAwesome;
  color      : #e2cf00;
  float      : left;
}

.star-rating span {
  overflow   : hidden;
  float      : left;
  top        : 0;
  left       : 0;
  position   : absolute;
  padding-top: 1.5em;
  color      : #e2cf00;
}

.star-rating span:before {
  content    : '\f005\f005\f005\f005\f005';
  top        : 0;
  position   : absolute;
  left       : 0;
  font-family: FontAwesome;
}

.star-rating span.width-10 {
  width: 10%;
}

.star-rating span.width-20 {
  width: 20%;
}

.star-rating span.width-30 {
  width: 30%;
}

.star-rating span.width-40 {
  width: 40%;
}

.star-rating span.width-50 {
  width: 50%;
}

.star-rating span.width-60 {
  width: 60%;
}

.star-rating span.width-70 {
  width: 70%;
}

.star-rating span.width-80 {
  width: 80%;
}

.star-rating span.width-90 {
  width: 90%;
}

.star-rating span.width-100 {
  width: 100%;
}

.stars {
  margin-bottom: 0;
}

.stars a.star-1,
.stars a.star-2,
.stars a.star-3,
.stars a.star-4,
.stars a.star-5 {
  width       : 2em;
  position    : relative;
  display     : inline-block;
  text-indent : -999px;
  margin-right: 1em;
  border-right: 1px solid #eee;
}

.stars a.star-1:before,
.stars a.star-2:before,
.stars a.star-3:before,
.stars a.star-4:before,
.stars a.star-5:before {
  content    : '\f006';
  top        : 0;
  position   : absolute;
  left       : 0;
  font-family: FontAwesome;
  color      : #e2cf00;
  float      : left;
  overflow   : hidden;
  text-indent: 0;
}

.stars a.star-1.active:before,
.stars a.star-1:hover:before {
  content: '\f005';
}

.stars a.star-2 {
  width: 3em;
}

.stars a.star-2:before {
  content: '\f006\f006';
}

.stars a.star-2.active:before,
.stars a.star-2:hover:before {
  content: '\f005\f005';
}

.stars a.star-3 {
  width: 4em;
}

.stars a.star-3:before {
  content: '\f006\f006\f006';
}

.stars a.star-3.active:before,
.stars a.star-3:hover:before {
  content: '\f005\f005\f005';
}

.stars a.star-4 {
  width: 5em;
}

.stars a.star-4:before {
  content: '\f006\f006\f006\f006';
}

.stars a.star-4.active:before,
.stars a.star-4:hover:before {
  content: '\f005\f005\f005\f005';
}

.stars a.star-5 {
  width       : 6em;
  border-right: none;
  margin-right: 0;
}

.stars a.star-5:before {
  content: '\f006\f006\f006\f006\f006';
}

.stars a.star-5.active:before,
.stars a.star-5:hover:before {
  content: '\f005\f005\f005\f005\f005';
}

.top-content .result-output {
  display   : inline-block;
  text-align: left;
  width     : 100%;
}

.top-content .result-output .result-count {
  color : #6f8190;
  margin: 0;
}

.edugate-tabs {
  margin-bottom: 40px;
  border       : none;
  display      : inline-block;
}

.edugate-tabs li {
  padding: 0 15px;
}

.edugate-tabs li .text {
  padding       : 0 0 10px 0;
  line-height   : 1;
  border        : none;
  color         : #49575f;
  text-transform: capitalize;
  position      : relative;
  cursor        : pointer;
}

.edugate-tabs li .text:after {
  position           : absolute;
  content            : '';
  width              : 0px;
  height             : 2px;
  /* background-color: #3275BB; */
  background-color   : #3275bb;
  bottom             : -2px;
  left               : 0;
  transition         : all 0.3s ease;
}

.edugate-tabs li .text:hover {
  background-color: transparent;
  border          : none;
}

.edugate-tabs li .text:hover:after {
  width: 100%;
}

.edugate-tabs li.active .text {
  border     : none;
  line-height: 1;
  /* color   : #3275BB; */
  color      : #3275bb;
}

.edugate-tabs li.active .text:after {
  position           : absolute;
  content            : '';
  width              : 100%;
  height             : 2px;
  /* background-color: #3275BB; */
  background-color   : #3275bb;
  bottom             : -2px;
  left               : 0;
  transition         : all 0.3s ease;
}

.edugate-tabs li.active .text:focus,
.edugate-tabs li.active .text:hover {
  border  : none;
  /* color: #3275BB; */
  color   : #3275bb;
}

.staff-item {
  cursor          : pointer;
  background-color: #fff;
  padding         : 30px;
  position        : relative;
  overflow        : hidden;
  transition      : all 0.8s ease;
  width           : 100%;
}

.staff-item.customize {
  background-color: #fafafa;
}

.staff-item.customize:before {
  content   : '';
  position  : absolute;
  top       : 0px;
  left      : 0px;
  border-top: 3px solid #eaedf5;
  transition: all 0.5s linear;
}

.staff-item.customize:after {
  content         : '';
  position        : absolute;
  z-index         : 1;
  width           : 100%;
  right           : 100%;
  top             : 0px;
  height          : 3px;
  background-color: #242c42;
  transition      : all 0.5s ease;
}

.staff-item .staff-item-wrapper {
  width     : 100%;
  height    : 235px;
  overflow  : hidden;
  transition: all 0.8s ease;
}

.staff-item .staff-info {
  text-align: center;
  overflow  : hidden;
}

.staff-item .staff-info .staff-avatar {
  display: block;
}

.staff-item .staff-info .staff-avatar img {
  border-radius: 50%;
  width        : 100%;
  max-width    : 150px;
  margin       : 0 auto;
  margin-bottom: 15px;
  transition   : all 0.8s ease;
}

.staff-item .staff-info .staff-name {
  font-size     : 18px;
  color         : #49575f;
  text-transform: uppercase;
  margin-bottom : 10px;
}

.staff-item .staff-info .staff-job {
  color         : #3275bb;
  text-transform: capitalize;
  margin-bottom : 25px;
}

.staff-item .staff-info .staff-desctiption {
  display   : none;
  text-align: justify;
  font-size : 13px;
  height    : 72px;
  overflow  : hidden;
  transition: all 0.8s ease;
  color     : white;
}

.staff-item .staff-socials {
  text-align: center;
}

.staff-item .staff-socials a {
  margin-right    : 10px;
  margin-bottom   : 10px;
  width           : 30px;
  height          : 30px;
  text-align      : center;
  line-height     : 30px;
  border-radius   : 3px;
  background-color: #a4abaf;
  display         : inline-block;
  color           : #fff;
  transition      : all 0.2s ease-in;
}

.staff-item .staff-name,
.staff-item .staff-job,
.staff-item .staff-socials a {
  transition: all 0.5s ease;
}

.staff-item:hover {
  /* background-color: #3275BB; */
  background-color: #3275bb;
}

.staff-item:hover .staff-info .staff-avatar img {
  max-width    : 90px;
  margin-bottom: 10px;
}

.staff-item:hover .staff-info .staff-name {
  color      : #fff;
  font-weight: bold;
}

.staff-item:hover .staff-info .staff-job {
  color        : #fff;
  margin-bottom: 10px;
}

.staff-item:hover .staff-info .staff-desctiption {
  display: block;
}

.staff-item:hover .staff-socials a {
  color           : #a4abaf;
  background-color: #fff;
}

.staff-item:hover .staff-socials a.facebook:hover {
  color: #3b5998;
}

.staff-item:hover .staff-socials a.google:hover {
  color: #dc4e41;
}

.staff-item:hover .staff-socials a.twitter:hover {
  color: #55acee;
}

.staff-item:hover.customize:after {
  right: 0px;
}

.staff-item:hover.customize .staff-desctiption {
  color: #fff;
}

.staff-item2 {
  cursor          : pointer;
  background-color: #fff;
  padding         : 30px;
  position        : relative;
  overflow        : hidden;
  transition      : all 0.8s ease;
}

.staff-item2.customize {
  background-color: #fafafa;
}

.staff-item2.customize:before {
  content   : '';
  position  : absolute;
  top       : 0px;
  left      : 0px;
  border-top: 3px solid #eaedf5;
  transition: all 0.5s linear;
}

.staff-item2.customize:after {
  content         : '';
  position        : absolute;
  z-index         : 1;
  width           : 100%;
  right           : 100%;
  top             : 0px;
  height          : 3px;
  background-color: #242c42;
  transition      : all 0.5s ease;
}

.staff-item2 .staff-item-wrapper {
  width     : 100%;
  height    : 235px;
  overflow  : hidden;
  transition: all 0.8s ease;
}

.staff-item2 .staff-info {
  text-align: center;
  overflow  : hidden;
}

.staff-item2 .staff-info .staff-avatar {
  display: block;
}

.staff-item2 .staff-info .staff-avatar img {
  border-radius: 50%;
  width        : 100%;
  max-width    : 150px;
  margin       : 0 auto;
  margin-bottom: 15px;
  transition   : all 0.8s ease;
}

.staff-item2 .staff-info .staff-name {
  font-size     : 18px;
  color         : #49575f;
  text-transform: uppercase;
  margin-bottom : 10px;
}

.staff-item2 .staff-info .staff-job {
  color         : #3275bb;
  text-transform: capitalize;
  margin-bottom : 25px;
}

.staff-item2 .staff-info .staff-desctiption {
  display   : none;
  text-align: justify;
  font-size : 13px;
  height    : 72px;
  overflow  : hidden;
  transition: all 0.8s ease;
}

.staff-item2 .staff-socials {
  text-align: center;
}

.staff-item2 .staff-socials a {
  margin-right    : 10px;
  margin-bottom   : 10px;
  width           : 30px;
  height          : 30px;
  text-align      : center;
  line-height     : 30px;
  border-radius   : 3px;
  background-color: #a4abaf;
  display         : inline-block;
  color           : #fff;
  transition      : all 0.2s ease-in;
}

.staff-item2 .staff-name,
.staff-item2 .staff-job,
.staff-item2 .staff-socials a {
  transition: all 0.5s ease;
}

#back-top a {
  position        : fixed;
  bottom          : 20px;
  right           : 20px;
  /* border       : 2px solid #3275BB; */
  border          : 2px solid #3275bb;
  background-color: transparent;
  width           : 40px;
  height          : 40px;
  text-align      : center;
  font-size       : 24px;
  z-index         : 100;
  /* color        : #3275BB; */
  color           : #3275bb;
  transition      : all 0.5s ease-in-out;
}

#back-top a:hover {
  background-color: #3275bb;
  color           : #fff;
}

.nav-search {
  width           : 320px;
  padding         : 5px 20px 20px 20px;
  background-color: #fff;
  box-shadow      : 0 5px 5px rgba(0, 0, 0, 0.2);
  position        : absolute;
  right           : 0;
  bottom          : -80px;
}

.nav-search form {
  display: table;
  width  : 100%;
  height : 40px;
  border : 1px solid #cccccc;
}

.nav-search form input[type='text'] {
  border : none;
  display: table-cell;
  width  : 225px;
  height : 40px;
  padding: 0 20px;
}

.nav-search form .searchbutton {
  width           : 50px;
  padding         : 0;
  display         : table-cell;
  border          : none;
  box-shadow      : none;
  background-color: transparent;
  height          : 100%;
  text-align      : right;
  padding-right   : 20px;
  vertical-align  : middle;
}

.nav-search form .searchbutton:hover {
  color: #3275bb;
}

.pagination {
  display   : inline-block;
  text-align: center;
  position  : relative;
  width     : 100%;
}

.pagination .pagination__list {
  list-style: none;
  padding   : 0;
  margin    : 0;
}

.pagination .pagination__list li {
  display     : inline-block;
  margin-right: 10px;
}

.pagination .pagination__list li:last-child {
  margin-right: 0;
}

.pagination .pagination__list .pagination__page {
  font-size: 14px;
}

.pagination .pagination__list .pagination__previous,
.pagination .pagination__list .pagination__next {
  position   : relative;
  top        : 1px;
  line-height: 36px;
}

.pagination .pagination__list .btn-squae {
  background-color: #242c42;
  color           : #fff;
}

.pagination .pagination__list .btn-squae:hover {
  background-color: #3275bb;
  color           : #fff;
}

.pagination .pagination__list .btn-squae.active {
  background-color: #3275bb;
  pointer-events  : none;
}

.btn-squae {
  width           : 40px;
  height          : 40px;
  line-height     : 40px;
  text-align      : center;
  color           : #fff;
  background-color: #3275bb;
  display         : inline-block;
  font-size       : 24px;
  font-family     : Helvetica, Arial, sans-serif;
  cursor          : pointer;
  transition      : all 0.2s linear;
}

.btn-squae:hover {
  background-color: #242c42;
  color           : #fff;
}

.btn-squae.active {
  background-color: #242c42;
  cursor          : pointer;
}

.btn-squae.disable {
  background-color: #ccc !important;
  pointer-events  : none;
  color           : #fff;
}

header .header-topbar {
  background-color: #242c42;
  width           : 100%;
  color           : #fff;
  line-height     : 50px;
}

header .header-topbar a,
header .header-topbar span,
header .header-topbar p {
  color     : #fff;
  font-size : 12px;
  transition: all 0.2s ease;
}

header .header-topbar a:hover,
header .header-topbar span:hover,
header .header-topbar p:hover {
  /* color: #3275BB; */
  color: #3275bb;
}

header .header-topbar .email,
header .header-topbar .hotline {
  display: inline-block;
}

header .header-topbar .email .topbar-icon,
header .header-topbar .hotline .topbar-icon {
  /* color: #3275BB; */
  color       : #3275bb;
  margin-right: 10px;
  font-size   : 16px;
}

header .header-topbar .email {
  margin-right: 20px;
}

header .header-topbar .socials,
header .header-topbar .group-sign-in {
  display    : inline-block;
  padding-top: 0;
}

header .header-topbar .socials a {
  color           : #a7abb3;
  padding         : 0px 10px;
  width           : auto;
  height          : auto;
  background-color: transparent;
}

header .header-topbar .socials a i {
  transition: all 0.3s ease;
}

header .header-topbar .socials a:hover i {
  /* color: #3275BB; */
  color: #3275bb;
}

header .header-topbar .socials a:last-child {
  margin-right: 0;
}

header .header-topbar .group-sign-in {
  margin-left   : 10px;
  text-transform: capitalize;
}

header .header-topbar .group-sign-in .login:after {
  content     : '|';
  margin-right: 10px;
  padding-left: 10px;
  color       : #fff !important;
}

header .header-topbar.homepage-03 {
  background-color: #fff;
  color           : #242c42;
  border-bottom   : 1px solid #e1e1e1;
  line-height     : 65px;
  position        : relative;
  z-index         : 101;
}

header .header-topbar.homepage-03 a,
header .header-topbar.homepage-03 span,
header .header-topbar.homepage-03 p {
  color: #242c42;
}

header .header-topbar.homepage-03 a:hover,
header .header-topbar.homepage-03 span:hover,
header .header-topbar.homepage-03 p:hover {
  color: #3275bb;
}

header .header-topbar.homepage-03 .logo-topbar img {
  margin: 15px auto;
  width : 150px;
}

header .header-topbar.homepage-03 .group-sign-in .login:after {
  color: #242c42 !important;
}

header .header-main {
  width           : 100%;
  background-color: #fff;
  box-shadow      : 3px 0 7px rgba(0, 0, 0, 0.3);
  position        : relative;
  z-index         : 100;
  transition      : all 0.3s ease-in-out;
}

header .header-main .logo {
  line-height: 100px;
}

header .header-main .logo .header-logo img {
  max-width : 220px;
  width     : 100%;
  transition: all 0.3s ease-in-out;
}

header .header-main .navigation {
  position: relative;
}

header .header-main .navigation .nav-links {
  position: relative;
}

header .header-main .navigation .nav-links li .main-menu {
  line-height     : 100px;
  padding         : 0;
  margin          : 0 15px;
  text-transform  : uppercase;
  color           : #242c42;
  position        : relative;
  background-color: transparent;
  transition      : all 0.3s ease-in-out;
}

header .header-main .navigation .nav-links li .main-menu:after {
  position  : absolute;
  content   : '';
  width     : 0;
  transition: all 0.3s ease-in-out;
}

header .header-main .navigation .nav-links li .main-menu:focus {
  background-color: transparent;
}

header .header-main .navigation .nav-links li.active .main-menu {
  /* border-bottom: 5px solid #3275BB;
    color: #3275BB; */
  border-bottom: 5px solid #3275bb;
  color        : #3275bb;
  font-weight  : bold;
}

header .header-main .navigation .nav-links li:hover .main-menu {
  background-color: transparent;
}

header .header-main .navigation .nav-links li:hover .main-menu:after {
  position           : absolute;
  content            : '';
  width              : 100%;
  bottom             : -5px;
  height             : 5px;
  /* background-color: #3275BB; */
  background-color   : #3275bb;
  left               : 0;
}

header .header-main .navigation .nav-links li.button-search {
  cursor: pointer;
}

header .header-main .navigation .nav-links li.button-search:hover .main-menu {
  background-color: transparent;
  color           : #3275bb;
}

header .header-main .navigation .nav-links li.button-search:hover .main-menu:after {
  display: none;
}

header .header-main .navigation .icons-dropdown {
  margin-left: 5px;
}

header .header-main .edugate-dropdown-menu-1,
header .header-main .edugate-dropdown-menu-2 {
  top             : 105px;
  min-width       : 240px;
  border-radius   : 0;
  border          : none;
  padding-bottom  : 0;
  /* border-bottom: 5px solid #3275BB; */
  border-bottom   : 5px solid #3275bb;
}

header .header-main .edugate-dropdown-menu-1 li,
header .header-main .edugate-dropdown-menu-2 li {
  transition: all 0.3s ease-in-out;
}

header .header-main .edugate-dropdown-menu-1 li .link-page,
header .header-main .edugate-dropdown-menu-2 li .link-page {
  line-height   : 40px;
  text-transform: capitalize;
  color         : #242c42;
  border        : none;
  font-weight   : normal;
}

header .header-main .edugate-dropdown-menu-1 li:hover,
header .header-main .edugate-dropdown-menu-2 li:hover {
  background-color: #f5f5f5;
}

header .header-main .edugate-dropdown-menu-2 {
  top        : 0;
  margin-top : 0;
  padding-top: 0;
}

header .header-main .edugate-dropdown-menu-2.left {
  left : 100%;
  right: auto;
}

header .header-main .edugate-dropdown-menu-2.right {
  right: 100%;
  left : auto;
}

header .header-main.header-fixed {
  position  : fixed;
  top       : 0;
  transition: all 0.6s ease-in-out;
}

header .header-main.header-fixed .logo {
  line-height: 50px;
}

header .header-main.header-fixed .logo .header-logo img {
  max-width : 125px;
  width     : 100%;
  transition: all 0.3s ease-in-out;
}

header .header-main.header-fixed .navigation .nav-links li .main-menu {
  line-height: 50px;
}

header .header-main.header-fixed .edugate-dropdown-menu-1 {
  top: 55px;
}

header .header-main.hide-menu {
  top: -66px !important;
}

header .header-main.homepage-03 .logo {
  display: none;
}

header .header-main.homepage-03 .navigation {
  text-align: center;
}

header .header-main.homepage-03 .navigation .nav-links {
  display: table;
  float  : none;
  margin : 0 auto;
}

header .header-main.homepage-03 .navigation .nav-links li .main-menu {
  line-height: 50px;
}

header .header-main.homepage-03 .navigation .nav-links li:hover .main-menu {
  background-color: transparent;
}

header .header-main.homepage-03 .navigation .nav-links li.button-search:hover .main-menu {
  background-color: transparent;
  color           : #3275bb;
}

header .header-main.homepage-03 .navigation .nav-links li.button-search:hover .main-menu:after {
  display: none;
}

header .header-main.homepage-03 .edugate-dropdown-menu-1 {
  top: 55px;
}

header .header-main.homepage-03.header-fixed {
  position                  : fixed;
  top                       : 0;
  -webkit-animation-name    : slideInDown;
  animation-name            : slideInDown;
  -webkit-animation-duration: 1s;
  animation-duration        : 1s;
  transition                : all 0.3s ease-in-out;
}

header .header-main.homepage-03.header-fixed .logo {
  display    : block;
  line-height: 50px;
  float      : left;
  position   : relative;
  z-index    : 10;
}

header .header-main.homepage-03.header-fixed .logo .header-logo img {
  max-width : 125px;
  width     : 100%;
  transition: all 0.3s ease-in-out;
}

header .header-main.homepage-03.header-fixed .navigation .nav-links {
  float: right;
}

header .header-main.homepage-03.header-fixed .navigation .nav-links li .main-menu:after {
  display: block;
}

header .header-main.homepage-03.header-fixed .navigation .nav-links li.active .main-menu {
  border-bottom: 5px solid #3275bb;
}

header .header-main.homepage-03.header-fixed .edugate-dropdown-menu-1 {
  top: 55px;
}

header .header-main.homepage-02 {
  display   : none;
  transition: all 0.5s ease-in-out;
}

header .header-main.homepage-02 .logo {
  line-height: 50px;
}

header .header-main.homepage-02 .logo .header-logo img {
  max-width: 125px;
  width    : 100%;
}

header .header-main.homepage-02 .navigation .nav-links li .main-menu {
  line-height: 50px;
}

header .header-main.homepage-02.header-fixed {
  position                  : fixed;
  top                       : 0;
  display                   : block;
  -webkit-animation-name    : slideInDown;
  animation-name            : slideInDown;
  -webkit-animation-duration: 1s;
  animation-duration        : 1s;
}

header .header-main.homepage-02 .edugate-dropdown-menu-1 li .link-page {
  line-height: 40px !important;
}

header .scroll-nav {
  overflow-y: scroll !important;
}

footer {
  width: 100%;
}

.footer-top {
  /* background-color: #3275BB; */
  background-color: #3275bb;
  color           : #fff;
}

.footer-top-wrapper {
  display: table;
  width  : 100%;
  height : 120px;
}

.footer-top-wrapper .footer-top-left {
  display       : table-cell;
  vertical-align: middle;
  text-align    : left;
  padding-right : 50px;
}

.footer-top-wrapper .footer-top-left .footer-top-focus {
  font-weight   : 900;
  font-size     : 30px;
  text-transform: uppercase;
  margin-bottom : 0;
}

.footer-top-wrapper .footer-top-left .footer-top-title,
.footer-top-wrapper .footer-top-left .footer-top-text {
  font-weight  : 300;
  font-size    : 16px;
  margin-bottom: 0;
}

.footer-top-wrapper .footer-top-right {
  display       : table-cell;
  vertical-align: middle;
  text-align    : right;
}

.footer-top-wrapper .footer-top-right input.btn-footer-top {
  background-color: #242c42;
  border          : none;
  padding         : 15px 30px;
  line-height     : 20px;
}

.footer-main {
  background-color: #242c42;
  overflow-x      : hidden;
}

.footer-main .footer-main-wrapper {
  padding-top   : 100px;
  padding-bottom: 40px;
  color         : #fff;
}

.footer-main .title-widget {
  /* color: #3275BB; */
  color         : #3275bb;
  padding-bottom: 40px;
  font-size     : 16px;
  text-align    : left;
}

.footer-main .hyperlink {
  border-top : 1px solid #2f374c;
  line-height: 70px;
  min-height : 70px;
  font-weight: bold;
  position   : relative;
}

.footer-main .hyperlink:before {
  position        : absolute;
  content         : '';
  width           : 100%;
  height          : 1px;
  background-color: #2f374c;
  top             : -1px;
  left            : -100%;
}

.footer-main .hyperlink:after {
  position        : absolute;
  content         : '';
  width           : 100%;
  height          : 1px;
  background-color: #2f374c;
  top             : -1px;
  right           : -100%;
}

.footer-main .hyperlink .hyper-left a {
  font-size: 10px;
  color    : #4f5b6b;
}

.footer-main .hyperlink .hyper-left a:hover {
  color: #3275bb;
}

.footer-main .hyperlink .hyper-right {
  font-size: 12px;
  color    : #4f5b6b;
}

.footer-main .hyperlink ul.list-inline {
  margin-bottom: 0;
}

.footer-main .widget a {
  color: #fff;
}

.footer-main .widget .cur-title {
  color: #3275bb;
}

.footer-main .recent-post-widget .content-widget a:hover,
.footer-main .popular-course-widget .content-widget a:hover,
.footer-main .category-widget .content-widget a:hover,
.footer-main .archive-widget .content-widget a:hover {
  color: #3275bb;
}

.footer-main .recent-post-widget .content-widget a:hover span,
.footer-main .popular-course-widget .content-widget a:hover span,
.footer-main .category-widget .content-widget a:hover span,
.footer-main .archive-widget .content-widget a:hover span,
.footer-main .recent-post-widget .content-widget a:hover p,
.footer-main .popular-course-widget .content-widget a:hover p,
.footer-main .category-widget .content-widget a:hover p,
.footer-main .archive-widget .content-widget a:hover p,
.footer-main .recent-post-widget .content-widget a:hover i,
.footer-main .popular-course-widget .content-widget a:hover i,
.footer-main .category-widget .content-widget a:hover i,
.footer-main .archive-widget .content-widget a:hover i {
  color: #3275bb;
}

.footer-main .category-widget li,
.footer-main .archive-widget li {
  border-bottom-color: #2f374c;
}

.footer-main .media {
  border-bottom-color: #2f374c;
}

.footer-main .tag-widget {
  margin: 20px 0px;
}

.footer-main .tag-widget li {
  margin : 0px 10px 10px 0px;
  display: inline-block;
}

.footer-main .tag-widget li .tag-item {
  color           : #fff;
  display         : inherit;
  padding         : 10px;
  background-color: #3b4757;
  transition      : all 0.3s ease-in;
}

.footer-main .tag-widget li .tag-item:hover {
  background-color: #3275bb;
}

.footer-main .tag-widget li .tag-item:hover .tag-item {
  color: #fff;
}

.footer-main .course-price-widget .cur-title.cur {
  color: #3275bb;
}

.sidebar a {
  color: #6f8190;
}

.sidebar .title-widget {
  background-color: #242c42;
  color           : #fff;
  text-align      : center;
  line-height     : 50px;
}

.sidebar .edugate-widget .content-widget,
.sidebar .useful-link-widget .content-widget,
.sidebar .gallery-widget .content-widget,
.sidebar .mailing-widget .content-widget {
  margin: 20px 0px;
}

.sidebar .widget:last-child {
  margin-bottom: 0 !important;
}

.widget {
  margin-bottom: 30px;
}

.widget a:hover {
  /* color: #3275BB; */
  color: #3275bb;
}

.widget p {
  line-height: 24px;
}

.widget .title-widget {
  font-weight   : bold;
  text-transform: uppercase;
}

.widget .media {
  border-bottom: 1px solid #dee5e9;
  padding      : 20px 0px;
  margin       : 0px;
}

.widget .media:last-child {
  border-bottom : none;
  padding-bottom: 0;
}

.widget .media .media-left {
  padding-right: 0;
  width        : 100px;
  height       : auto;
}

.widget .media .media-right {
  padding-left: 15px;
}

.widget .media .media-right span {
  font-size: 12px;
}

.widget .media .media-right .comment {
  float: right;
}

.widget .media .media-right .comment i {
  margin-right: 3px;
}

.widget .media .media-right p {
  margin: 0px;
}

.widget .media .info {
  display: inline-block;
}

.widget .media .link {
  display: inline-block;
  color  : #6f8190;
}

.widget .media .link .media-image {
  width: 100%;
}

.widget .media .link:hover {
  color: #3275bb;
}

.edugate-widget p {
  margin-bottom: 20px;
}

.edugate-widget .info-list ul {
  margin-bottom: 0px;
}

.edugate-widget .info-list ul li {
  display       : table;
  border-top    : 1px solid #2f374c;
  width         : 100%;
  padding-bottom: 15px;
  padding-top   : 15px;
}

.edugate-widget .info-list ul li>i {
  display: table-cell;
  width  : 25px;
}

.edugate-widget .info-list ul li>a {
  display: table-cell;
}

.edugate-widget .info-list p {
  margin-bottom: 0px;
}

.useful-link-list ul {
  padding: 0px;
  margin : 0px;
}

.useful-link-list ul li {
  margin-bottom: 15px;
  display      : table;
  line-height  : 24px;
}

.useful-link-list ul li a {
  display: table-cell;
}

.useful-link-list ul li i {
  display    : table-cell;
  width      : 15px;
  /* color   : #3275BB; */
  color      : #3275bb;
  font-weight: bold;
}

.gallery-widget .gallery-list a {
  padding: 0 5px 0 0;
  display: inline-block;
  width  : 25%;
}

.gallery-widget .gallery-list a:last-child {
  margin-right: 0px;
}

.gallery-widget .gallery-list a img {
  width: 100%;
}

.gallery-widget .view-more {
  font-size: 12px;
}

.gallery-widget .view-more i {
  color: #3275bb;
}

.mailing-widget p {
  margin-bottom: 15px;
}

.mailing-widget input.form-control {
  border-bottom-left-radius: 0px;
  border-top-left-radius   : 0px;
}

.mailing-widget .btn-email {
  /* background-color: #3275BB; */
  background-color: #3275bb;
  line-height     : 33px;
  text-align      : center;
  padding         : 0px 14px;
  width           : 39px;
}

.mailing-widget .input-group {
  margin-bottom: 15px;
}

.mailing-widget .form-email-widget {
  height          : 33px;
  background-color: #fafafa;
  border-radius   : 0px;
  border          : none;
  box-shadow      : none;
}

.mailing-widget .form-email-widget:focus,
.mailing-widget .form-email-widget:active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.socials {
  border-top : 1px solid #2f374c;
  padding-top: 15px;
  display    : block;
}

.socials a,
.socials .link {
  margin-right    : 10px;
  margin-bottom   : 10px;
  width           : 30px;
  height          : 30px;
  text-align      : center;
  line-height     : 30px;
  border-radius   : 3px;
  background-color: #3b4757;
  display         : inline-block;
  transition      : all 0.3s ease-in;
}

.socials a:last-child,
.socials .link:last-child {
  margin-right: 0;
}

.socials a i,
.socials .link i {
  color: #fff;
}

.socials a:hover,
.socials .link:hover {
  background-color: #3275bb;
}

.social-widget {
  margin-top : 30px;
  border     : none;
  padding-top: 0px;
}

.social-widget a,
.social-widget .link {
  background-color: #a8b0b6;
  margin-bottom   : 10px;
  margin-right    : 8px;
}

.cur {
  color      : #3275bb;
  font-weight: 900;
  font-size  : 20px;
  margin-top : -5px;
  position   : relative;
  margin-left: 7px;
}

.cur:before {
  position : absolute;
  content  : '$';
  top      : 0px;
  left     : -8px;
  font-size: 13px;
}

.category-widget li,
.archive-widget li {
  border-bottom      : 1px solid #dee5e9;
  padding            : 15px 0px;
  display            : -webkit-flex;
  -webkit-align-items: center;
  display            : -ms-flexbox;
  display            : flex;
  -webkit-align-items: center;
  -ms-flex-align     : center;
  align-items        : center;
}

.category-widget li:last-child,
.archive-widget li:last-child {
  border-bottom : none;
  padding-bottom: 0;
}

.category-widget li .link,
.archive-widget li .link {
  color: #6f8190;
  width: 100%;
}

.category-widget li .link span,
.archive-widget li .link span {
  color: #6f8190;
}

.category-widget li .link:hover,
.archive-widget li .link:hover {
  color: #3275bb;
}

.category-widget li .link:hover span,
.archive-widget li .link:hover span {
  color: #3275bb;
}

.tag-widget {
  margin-top: 10px;
}

.tag-widget li {
  background-color: #e9eef2;
  margin          : 10px 10px 0px 0px;
  display         : inline-block;
  transition      : all 0.3s ease-in;
}

.tag-widget li .tag-item {
  color  : #242c42;
  display: inherit;
  padding: 10px;
}

.tag-widget li:hover {
  background-color: #242c42;
}

.tag-widget li:hover .tag-item {
  color: #fff;
}

.course-price-widget .title-widget p {
  position     : relative;
  line-height  : 80px;
  font-size    : 48px;
  font-weight  : 700;
  display      : inline-block;
  margin-bottom: 0;
}

.course-price-widget .title-widget p:before {
  position : absolute;
  content  : '$';
  font-size: 25px;
  top      : -15px;
  left     : -16px;
}

.course-price-widget-wrapper {
  background-color: #fafafa;
  padding         : 30px;
  margin-bottom   : 0;
  color           : #6f8190;
  text-align      : center;
}

.course-price-widget-wrapper .title {
  color: #242c42;
}

.course-price-widget-wrapper .length {
  position: relative;
  left    : -38px;
}

.course-price-widget-wrapper .length:after {
  position: absolute;
  content : 'hours';
  left    : 22px;
}

.course-price-widget-wrapper .firgue {
  position: relative;
  left    : -44px;
}

.course-price-widget-wrapper .firgue:after {
  position: absolute;
  content : 'people';
  left    : 19px;
}

.course-price-widget-wrapper .info-row {
  margin-bottom: 15px;
}

.course-price-widget-wrapper .info-row:last-child {
  margin-bottom: 25px;
}

.course-price-widget-wrapper span {
  text-align: left;
}

.author-widget-wrapper {
  background-color: #fafafa;
  margin-bottom   : 0;
}

.author-widget-wrapper.customize:before {
  display   : none;
  visibility: hidden;
}

.author-widget-wrapper.customize:after {
  display   : none;
  visibility: hidden;
}

.slider-banner {
  position: relative;
}

.slider-banner .owl-controls {
  display    : inline-block;
  position   : absolute;
  bottom     : 5%;
  left       : 50%;
  margin-left: -30px;
}

.slider-banner .owl-controls .owl-dots .owl-dot {
  width           : 12px;
  height          : 12px;
  background-color: transparent;
  border          : 2px solid #bfc6d7;
  display         : inline-block;
  border-radius   : 50%;
  margin          : 0 5px;
}

.slider-banner .owl-controls .owl-dots .owl-dot.active {
  /* background-color: #3275BB;
    border: 2px solid #3275BB; */
  background-color: #3275bb;
  border          : 2px solid #3275bb;
}

.slider-banner-02,
.slider-banner-03 {
  background-repeat    : repeat;
  background-attachment: fixed;
  background-position  : center;
  width                : 100%;
  min-height           : 568px;
}

.slider-banner-02 .container,
.slider-banner-03 .container {
  display: table;
  height : 100%;
}

.slider-banner-02 .slider-banner-wrapper,
.slider-banner-03 .slider-banner-wrapper {
  display       : table-cell;
  vertical-align: middle;
  text-align    : center;
  text-transform: uppercase;
  position      : relative;
  z-index       : 2;
}

.slider-banner-02 .slider-banner-wrapper .sub-title,
.slider-banner-03 .slider-banner-wrapper .sub-title {
  font-size    : 25px;
  margin       : 0;
  margin-bottom: 30px;
  font-weight  : 300;
}

.slider-banner-02 .slider-banner-wrapper .main-title,
.slider-banner-03 .slider-banner-wrapper .main-title {
  font-size    : 72px;
  margin       : 0;
  margin-bottom: 50px;
  font-weight  : 700;
}

.slider-banner-02 {
  background-image: url('../../src/assets/images/banner-2.jpg');
  height          : 100vh;
  color           : #49575f;
  background-size : cover;
  position        : relative;
}

.slider-banner-02 .logo-banner img {
  margin       : 0 auto;
  margin-bottom: 25px;
}

.slider-banner-02 .arrow-down {
  color      : #49575f;
  position   : absolute;
  bottom     : 20px;
  display    : block;
  width      : 50px;
  height     : 50px;
  line-height: 30px;
  left       : 50%;
  margin-left: -25px;
}

.slider-banner-02 .arrow-down i {
  font-size: 50px;
}

.slider-banner-03 {
  background-image   : url('../../src/assets/images/background-best-staff.jpg');
  color              : #fff;
  background-position: top;
}

.slider-item {
  color: #242c42;
}

.slider-item .slider-1,
.slider-item .slider-2,
.slider-item .slider-3,
.slider-item .slider-4 {
  background-image   : url(../../src/assets/images/background-slider-21.jpg);
  background-position: center;
  background-size    : cover;
  background-repeat  : no-repeat;
  height             : 100%;
  width              : 100%;
  display            : table;
}

.slider-item .slider-2 {
  /* background-image: url(../../src/assets/images/bg-404.jpg) */
  background-image: url(../../src/assets/images/slide/slide_1.jpg);
}

.slider-item .slider-2 .slider-caption {
  text-align: center;
}

.slider-item .slider-3 {
  /* background-image: url(../../src/assets/images/banner-2.jpg); */
  background-image: url(../../src/assets/images/slide/slide_3.jpg);
}

.slider-item .slider-4 {
  /* background-image: url(../../src/assets/images/banner-2.jpg); */
  background-image: url(../../src/assets/images/slide/slide_2.jpg);
}

.slider-item .slider-3 .slider-caption {
  text-align: center;
}

.slider-item .slider-caption {
  width         : 100%;
  display       : table-cell;
  vertical-align: middle;
}

.slider-item .slider-caption .text-info-2 {
  margin                 : 0 0 10px 0;
  font-size              : 30px;
  font-weight            : 300;
  -webkit-animation-delay: 0.8s;
  animation-delay        : 0.8s;
}

.slider-item .slider-caption .text-info-1 {
  margin                 : 0 0 10px 0;
  font-size              : 60px;
  font-weight            : 900;
  -webkit-animation-delay: 0.8s;
  animation-delay        : 0.8s;
}

.slider-item .slider-caption .text-info-3 {
  margin                 : 0 0 23px 0;
  font-size              : 20px;
  font-weight            : 700;
  -webkit-animation-delay: 0.8s;
  animation-delay        : 0.8s;
}

.slider-item .slider-caption .btn p,
.slider-item .slider-caption .btn span {
  line-height            : 46px;
  font-size              : 22px;
  font-weight            : 700;
  -webkit-animation-delay: 1.5s;
  animation-delay        : 1.5s;
  text-transform         : capitalize;
}

.progress-bars {
  background-image   : url('../../src/assets/images/background-count-number.png');
  background-repeat  : repeat;
  background-position: center;
  width              : 100%;
  color              : #fff;
}

.progress-bars .container {
  height: 100%;
}

.progress-bars .progress-bars-content {
  height: 100%;
}

.progress-bars .progress-bar-wrapper {
  width     : 100%;
  text-align: center;
}

.progress-bars .progress-bar-wrapper .title-2 {
  margin-bottom: 50px;
}

.progress-bars .progress-bar-wrapper .group-btn-slider {
  display: none;
}

.progress-bar-number {
  text-align   : center;
  font-weight  : 900;
  font-size    : 60px;
  margin-bottom: 50px;
}

.progress-bar-number .num {
  position     : relative;
  margin-bottom: 12px;
}

.progress-bar-number .num:after {
  content    : '+';
  margin-left: 5px;
}

.progress-bar-number .num:before {
  content            : '';
  position           : absolute;
  width              : 36px;
  height             : 2px;
  bottom             : 0;
  left               : 50%;
  margin-left        : -18px;
  /* background-color: #3275BB; */
  background-color   : #3275bb;
}

.progress-bar-number .name-inner {
  margin        : 0;
  font-weight   : 400;
  font-size     : 18px;
  text-transform: capitalize;
}

.why-choose-us {
  background-color: #242c42;
  color           : #fff;
}

.why-choose-us .why-choose-us-wrapper {
  text-align: left;
  width     : 100%;
}

.why-choose-us .why-choose-us-wrapper-top {
  position: relative;
}

.why-choose-us .why-choose-us-wrapper-top .why-choose-us-wrapper {
  padding      : 60px 0;
  padding-right: 25%;
}

.why-choose-us .why-choose-us-wrapper-top .why-choose-us-wrapper p {
  color        : #d1d8eb;
  line-height  : 25px;
  margin-bottom: 30px;
  font-size    : 16px;
}

.why-choose-us .why-choose-us-wrapper-top .why-choose-us-wrapper button p {
  color     : #fff;
  transition: all 1s ease;
}

.why-choose-us .why-choose-us-wrapper-top .background-girl-1 {
  position: absolute;
  width   : 23%;
  left    : 75%;
  bottom  : 0;
}

.why-choose-us .why-choose-us-wrapper-bottom {
  background-image   : url('../../src/assets/images/background-why-choose-us.jpg');
  background-repeat  : no-repeat;
  background-size    : cover;
  background-position: center center;
  width              : 100%;
  color              : #fff;
  position           : relative;
  z-index            : 2;
}

.why-choose-us .why-choose-us-wrapper-bottom .why-choose-us-wrapper {
  padding: 60px 0;
}

.why-choose-us .why-choose-us-wrapper-bottom .customs-row {
  display: inline;
}

.section-icon {
  margin : 22px 0;
  display: table;
}

.section-icon i {
  font-size     : 36px;
  /* color      : #3275BB; */
  color         : #3275bb;
  display       : table-cell;
  vertical-align: top;
}

.section-icon p,
.section-icon a,
.section-icon span {
  font-size     : 16px;
  display       : inline-block;
  margin        : 0;
  line-height   : 24px;
  padding-left  : 25px;
  display       : table-cell;
  vertical-align: middle;
}

.slider-logo {
  background-color: #fff;
  width           : 100%;
}

.slider-logo .slider-logo-wrapper {
  display       : block;
  padding-top   : 60px;
  padding-bottom: 60px;
  width         : 100%;
}

.slider-logo .slider-logo-wrapper .slider-logo-content {
  display: block;
  width  : 100%;
}

.slider-logo .slider-logo-wrapper .logo-iteam {
  opacity   : 0.5;
  transition: all 0.3s linear;
}

.slider-logo .slider-logo-wrapper .logo-iteam:hover {
  opacity: 1;
}

.slider-logo .container {
  overflow: hidden;
}

.slider-talk-about-us {
  background-image     : url('../../src/assets/images/background-talk-about-us.jpg');
  background-repeat    : no-repeat;
  background-attachment: fixed;
  background-position  : center center;
  color                : #fff;
  background-size      : cover;
}

.slider-talk-about-us .container {
  overflow: hidden;
}

.slider-talk-about-us .slider-talk-about-us-wrapper {
  width  : 100%;
  padding: 100px 0;
  display: table;
}

.slider-talk-about-us .slider-talk-about-us-wrapper .slider-talk-about-us-content {
  display       : table-cell;
  vertical-align: middle;
}

.slider-talk-about-us .peopel-item {
  text-align: center;
}

.slider-talk-about-us .peopel-item .peopel-comment {
  margin       : 0 auto;
  text-align   : left;
  width        : 72%;
  background   : rgba(255, 255, 255, 0.35);
  padding      : 35px;
  line-height  : 25px;
  margin-bottom: 35px;
  position     : relative;
}

.slider-talk-about-us .peopel-item .peopel-comment:after {
  position    : absolute;
  content     : '';
  width       : 0;
  height      : 0;
  border-left : 10px solid transparent;
  border-right: 10px solid transparent;
  border-top  : 18px solid rgba(255, 255, 255, 0.35);
  left        : 50%;
  margin-left : -10px;
  bottom      : -18px;
}

.slider-talk-about-us .peopel-item .group-peole-info .peopel-avatar {
  width        : 100px;
  height       : 100px;
  margin       : 0 auto;
  margin-bottom: 15px;
}

.slider-talk-about-us .peopel-item .group-peole-info .peopel-avatar img {
  border-radius: 50%;
}

.slider-talk-about-us .peopel-item .group-peole-info .peopel-name {
  font-weight   : 900;
  font-size     : 15px;
  /* color      : #3275BB; */
  color         : #3275bb;
  text-transform: capitalize;
}

.slider-talk-about-us .peopel-item .group-peole-info .people-job {
  font-size     : 12px;
  text-transform: capitalize;
}

.slider-talk-about-us.style-2 {
  background: #fafafa;
}

.slider-talk-about-us.style-2 .peopel-item .peopel-comment {
  background-color: #e5eaef;
  color           : #49575f;
}

.slider-talk-about-us.style-2 .peopel-item .group-peole-info .people-job {
  color: #49575f;
}

.slider-talk-about-us.style-2 .group-btn-slider {
  display: none;
}

.slider-talk-about-us .carousel-indicators li {
  width           : 12px;
  height          : 12px;
  background-color: transparent;
  border          : 2px solid #bfc6d7;
  display         : inline-block;
  border-radius   : 50%;
  margin          : 0 5px;
}

.slider-talk-about-us .carousel-indicators li.active {
  background-color: #3275bb;
  border          : 2px solid #3275bb;
}

.pricing>.container>.row {
  margin-bottom: 30px;
}

.pricing .pricing-widget {
  cursor: pointer;
}

.pricing .pricing-widget .pricing-header {
  position: relative;
  z-index : 2;
}

.pricing .pricing-widget .pricing-header .price-cost {
  background-color: #eaedf5;
  color           : #242c42;
  display         : table;
  width           : 120px;
  height          : 120px;
  margin          : 0 auto;
  border-radius   : 50%;
  margin-bottom   : -50px;
  border          : 10px solid #fff;
  transition      : all 0.25s linear;
}

.pricing .pricing-widget .pricing-header .price-cost .inner {
  display       : table-cell;
  vertical-align: middle;
  text-align    : center;
}

.pricing .pricing-widget .pricing-header .price-cost .inner .inner-number {
  position   : relative;
  font-size  : 40px;
  font-weight: 700;
  text-align : center;
  display    : inline-block;
  margin     : 0;
}

.pricing .pricing-widget .pricing-header .price-cost .inner .inner-number:after {
  content    : '$';
  font-size  : 20px;
  position   : absolute;
  left       : -10px;
  top        : 0;
  font-weight: 500;
}

.pricing .pricing-widget .pricing-content {
  position        : relative;
  background-color: #eaedf5;
  padding-top     : 60px;
  text-align      : center;
  top             : 15px;
  color           : #49575f;
  transition      : all 0.25s linear;
}

.pricing .pricing-widget .pricing-content .pricing-title {
  margin        : 0 0 8px 0;
  font-size     : 30px;
  text-transform: uppercase;
}

.pricing .pricing-widget .pricing-content .pricing-subtitle {
  margin-bottom: 30px;
}

.pricing .pricing-widget .pricing-content .pricing-list {
  list-style      : none;
  padding         : 30px;
  margin          : 0 10px;
  background-color: #fff;
  color           : #8c9aa5;
  text-transform  : capitalize;
}

.pricing .pricing-widget .pricing-content .pricing-list li {
  margin-bottom: 25px;
}

.pricing .pricing-widget .pricing-content .pricing-list li:last-child {
  margin-bottom: 0;
}

.pricing .pricing-widget .pricing-content .pricing-list li p {
  margin-bottom: 0;
}

.pricing .pricing-widget .pricing-content .pricing-list strong {
  color: #49575f;
}

.pricing .pricing-widget .pricing-content .pricing-button {
  font-weight   : 900;
  line-height   : 60px;
  width         : 100%;
  text-transform: uppercase;
  display       : inline-block;
}

.pricing .pricing-widget .pricing-content .pricing-button a {
  color: #242c42;
}

.pricing .pricing-widget:hover .pricing-header .price-cost,
.pricing .pricing-widget.active .pricing-header .price-cost {
  /* background-color: #3275BB; */
  background-color : #3275bb;
  color            : #fff;
  box-shadow       : 0 8px 15px rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-15px);
  transform        : translateY(-15px);
}

.pricing .pricing-widget:hover .pricing-content,
.pricing .pricing-widget.active .pricing-content {
  /* background-color: #3275BB; */
  background-color : #3275bb;
  color            : #fff;
  box-shadow       : 0 8px 15px rgba(0, 0, 0, 0.15);
  -webkit-transform: scale(1.02);
  transform        : scale(1.02);
}

.pricing .pricing-widget:hover .pricing-button a,
.pricing .pricing-widget.active .pricing-button a {
  color: #fff;
}

.pricing .group-btn-slider {
  display: none;
}

.pricing .group-btn-slider .btn-prev,
.pricing .group-btn-slider .btn-next {
  color: #000;
}

.best-staff {
  background-image     : url('../../src/assets/images/background-best-staff.jpg');
  background-repeat    : no-repeat;
  background-attachment: fixed;
  background-position  : center center;
  color                : #fff;
  background-size      : cover;
}

.best-staff .container {
  overflow : hidden;
  margin   : 0 auto;
  max-width: 1140px;
}

.best-staff .best-staff-wrapper {
  width : 100%;
  height: auto;
}

.best-staff .group-title-index {
  color: #fff;
}

.best-staff .group-btn-slider .btn-prev {
  top: 60%;
}

.best-staff .group-btn-slider .btn-next {
  top: 60%;
}

.latest-news .btn-latest-new {
  margin    : 0 auto;
  margin-top: 50px;
  display   : block;
}

.top-courses {
  text-align: center;
}

.top-courses .group-btn-top-courses-slider {
  display   : inline-block;
  margin    : 0 auto;
  text-align: center;
  margin-top: 30px;
}

.top-courses .group-btn-top-courses-slider .btn-prev,
.top-courses .group-btn-top-courses-slider .btn-next {
  width              : 40px;
  height             : 40px;
  line-height        : 36px;
  text-align         : center;
  color              : #fff;
  /* background-color: #3275BB; */
  background-color   : #3275bb;
  display            : inline-block;
  font-size          : 24px;
  font-family        : Helvetica, Arial, sans-serif;
  cursor             : pointer;
  transition         : all 0.2s linear;
}

.top-courses .group-btn-top-courses-slider .btn-prev:hover,
.top-courses .group-btn-top-courses-slider .btn-next:hover {
  background-color: #242c42;
}

.top-courses .group-btn-top-courses-slider .btn-prev {
  margin-right: 10px;
}

.top-courses .top-courses-item {
  padding   : 30px 0;
  text-align: left;
}

.top-courses .top-courses-slider {
  margin-bottom: 30px;
}

.top-courses .top-courses-wrapper {
  position     : relative;
  top          : -30px;
  margin-bottom: -30px;
}

.choose-course .icon-circle {
  position     : relative;
  width        : 170px;
  height       : 170px;
  border-radius: 50%;
  margin       : 0 auto;
}

.choose-course .icon-circle .icon-background {
  position                : absolute;
  bottom                  : 12px;
  width                   : 146px;
  left                    : 50%;
  margin-left             : -73px;
  height                  : 146px;
  border-radius           : 50%;
  /* background-color     : #3275BB; */
  background-color        : #3275bb;
  color                   : #fff;
  z-index                 : 11;
  display                 : table;
  transition              : all 0.5s ease-in-out;
  -webkit-transform-origin: 50% 0;
  transform-origin        : 50% 0;
}

.choose-course .icon-circle .icon-background .icons-img {
  text-align    : center;
  margin        : 0 auto;
  color         : #fff;
  display       : table-cell;
  vertical-align: middle;
  font-size     : 70px;
  line-height   : 1;
  transition    : all 0.4s ease;
}

.choose-course .icon-circle .icon-background:after {
  position     : absolute;
  content      : '';
  top          : -2px;
  left         : -2px;
  bottom       : -2px;
  right        : -2px;
  border       : 3px solid #fff;
  border-radius: 50%;
  transition   : all 0.4s ease;
}

.choose-course .icon-circle .info {
  position                   : absolute;
  top                        : 0;
  bottom                     : 0;
  left                       : 0;
  right                      : 0;
  text-align                 : center;
  border-radius              : 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility        : hidden;
}

.choose-course .icon-circle .info .info-back {
  opacity         : 1;
  border-radius   : 50%;
  background-color: #242c42;
  width           : 100%;
  height          : 100%;
  display         : table;
  position        : relative;
  transition      : all 0.3s ease;
}

.choose-course .icon-circle .info .info-back:before {
  position     : absolute;
  z-index      : 20;
  content      : '';
  top          : -1px;
  left         : -1px;
  bottom       : -1px;
  right        : -1px;
  /* border    : 12px solid #3275BB; */
  border       : 12px solid #3275bb;
  border-radius: 50%;
}

.choose-course .icon-circle .info .info-back a {
  color         : #fff;
  vertical-align: middle;
  padding       : 20px;
  display       : table-cell;
  position      : relative;
  z-index       : 2;
  transition    : all 0.7s ease;
}

.choose-course .icon-circle:hover .icon-background {
  /* background-color: #3275BB; */
  background-color: #3275bb;
  bottom          : 90%;
}

.choose-course .icon-circle:hover .icon-background:after {
  border: 2px solid #fff;
}

.choose-course .icon-circle:hover .info .info-back:before {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.6);
}

.choose-course .icon-circle:hover .info .info-back a {
  transition-delay: 1s;
  z-index         : 21;
}

.choose-course .name-course {
  margin-top: 25px;
  color     : #49575f;
  text-align: center;
  font-size : 16px;
}

.choose-course .name-course a,
.choose-course .name-course p,
.choose-course .name-course span {
  color         : #49575f;
  font-size     : 16px;
  text-transform: uppercase;
  font-weight   : 700;
  transition    : all 0.2s linear;
}

.choose-course .name-course i {
  margin-left: 5px;
  transition : all 0.2s linear;
}

.choose-course .name-course:hover a,
.choose-course .name-course:hover p,
.choose-course .name-course:hover span,
.choose-course .name-course:hover i {
  color: #3275bb;
}

.choose-course .choose-course-wrapper .col-md-4.col-xs-6 {
  margin-bottom: 70px;
}

.choose-course .choose-course-wrapper .col-md-4.col-xs-6:nth-last-child(-n + 3) {
  margin-bottom: 0;
}

.choose-course-2 .item-course {
  width     : 100%;
  cursor    : pointer;
  border    : 1px solid #e1e1e1;
  position  : relative;
  text-align: center;
  overflow  : hidden;
  padding   : 70px 0;
  transition: all 0.5s ease-in-out;
}

.choose-course-2 .item-course .icon-course .icons-img {
  font-size  : 64px;
  line-height: 80px;
  color      : #3275bb;
  transition : all 0.5s ease-in-out;
}

.choose-course-2 .item-course .info-course {
  margin-top: 15px;
}

.choose-course-2 .item-course .info-course .name-course {
  font-size    : 21px;
  color        : #49575f;
  margin-bottom: 10px;
  font-weight  : 700;
}

.choose-course-2 .item-course .info-course .info {
  font-size: 18px;
  color    : #6f8190;
}

.choose-course-2 .item-course .hover-text {
  position        : absolute;
  top             : 100%;
  width           : 100%;
  height          : 100%;
  background-color: #3275bb;
  color           : #fff;
  transition      : all 0.5s ease-in-out;
}

.choose-course-2 .item-course .hover-text .wrapper-hover-text {
  display: table;
  height : 100%;
  width  : 100%;
}

.choose-course-2 .item-course .hover-text .wrapper-hover-content {
  display       : table-cell;
  vertical-align: middle;
  text-align    : center;
  padding       : 0 30px;
  line-height   : 24px;
}

.choose-course-2 .item-course .hover-text .wrapper-hover-content .title {
  text-transform: uppercase;
  margin-bottom : 20px;
  color         : #fff;
}

.choose-course-2 .item-course .hover-text .wrapper-hover-content .title:hover {
  border-bottom: 1px solid #fff;
}

.choose-course-2 .item-course:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.choose-course-2 .item-course:hover .hover-text {
  top: 0;
}

.choose-course-2 .choose-course-wrapper .col-md-4.col-xs-6 {
  margin-bottom: 30px;
}

.choose-course-2 .choose-course-wrapper .col-md-4.col-xs-6:nth-last-child(-n + 3) {
  margin-bottom: 0;
}

.choose-course-3 .choose-course-3-wrapper.row {
  margin: 0;
}

.choose-course-3 .item-course {
  width        : 33.33333333%;
  float        : left;
  cursor       : pointer;
  border       : 1px solid #e1e1e1;
  border-left  : 0;
  border-bottom: 0;
  position     : relative;
  display      : inline-block;
  transition   : all 0.5s ease-in-out;
}

.choose-course-3 .item-course:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.choose-course-3 .item-course:hover .icon-course .icons-img {
  color: #242c42;
}

.choose-course-3 .item-course:hover:before {
  position: absolute;
  content : '';
  left    : -1px;
  right   : -1px;
  top     : -1px;
  bottom  : -1px;
  border  : 1px solid #e1e1e1;
}

.choose-course-3 .item-course:nth-child(3n-2) {
  border-left: 1px solid #e1e1e1;
}

.choose-course-3 .item-course:nth-last-child(-n + 3) {
  border-bottom: 1px solid #e1e1e1;
}

.choose-course-3 .item-course-wrapper {
  display: table;
  padding: 16% 10%;
}

.choose-course-3 .item-course-wrapper .icon-course {
  display       : table-cell;
  vertical-align: top;
  padding-right : 30px;
  position      : relative;
}

.choose-course-3 .item-course-wrapper .icon-course:after {
  position  : absolute;
  content   : '';
  background: #e1e1e1;
  right     : 0;
  top       : 0;
  bottom    : 0;
  width     : 1px;
  height    : 100%;
  min-height: 90px;
}

.choose-course-3 .item-course-wrapper .icon-course .icons-img {
  font-size  : 64px;
  width      : 64px;
  line-height: 1;
  color      : #3275bb;
  transition : all 0.5s ease-in-out;
}

.choose-course-3 .item-course-wrapper .info-course {
  display       : table-cell;
  vertical-align: top;
  padding-left  : 30px;
  color         : #49575f;
}

.choose-course-3 .item-course-wrapper .info-course .name-course {
  font-size     : 16px;
  text-transform: uppercase;
  margin-bottom : 20px;
  display       : block;
  position      : relative;
  z-index       : 2;
  color         : #49575f;
}

.choose-course-3 .item-course-wrapper .info-course .info {
  line-height: 24px;
}

.picture-gallery {
  padding-bottom: 0;
}

.picture-gallery .picture-gallery-wrapper {
  text-align: center;
}

.picture-gallery .gallery-content .grid-item {
  float: left;
  width: 25%;
}

.picture-gallery .gallery-content .grid-item:before {
  content: '';
  display: block;
}

.picture-gallery .gallery-content .grid-item .content {
  position: absolute;
  top     : 0;
  left    : 0;
  bottom  : 0;
  right   : 0;
  overflow: hidden;
}

.picture-gallery .gallery-content .grid-item .content:after {
  position         : absolute;
  content          : '';
  top              : 0;
  left             : 0;
  right            : 0;
  bottom           : 0;
  background-color : rgba(10, 15, 28, 0.7);
  opacity          : 0;
  -webkit-animation: zoomIn;
  animation        : zoomIn;
  transition       : all 0.5s ease;
}

.picture-gallery .gallery-content .grid-item .content:before {
  position       : absolute;
  content        : '';
  width          : 50px;
  height         : 50px;
  background     : url(../../src/assets/images/lib-pictures/icon-plus.png);
  background-size: cover;
  left           : 50%;
  top            : 50%;
  margin-left    : -25px;
  margin-top     : -25px;
  color          : #fff;
  opacity        : 0;
  z-index        : 2;
  transition     : all 0.5s ease;
}

.picture-gallery .gallery-content .grid-item img {
  width            : 100%;
  height           : 100%;
  webkit-transition: all 0.5s ease;
  transition       : all 0.5s ease;
}

.picture-gallery .gallery-content .grid-item:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  z-index   : 10;
}

.picture-gallery .gallery-content .grid-item:hover .content img {
  -webkit-transform: scale(1.5);
  transform        : scale(1.5);
}

.picture-gallery .gallery-content .grid-item:hover .content:after {
  opacity: 1;
}

.picture-gallery .gallery-content .grid-item:hover .content:before {
  opacity: 1;
}

.picture-gallery .gallery-content .grid-item-height1:before {
  padding-top: 50%;
}

.picture-gallery .gallery-content .grid-item-height2:before {
  padding-top: 100%;
}

.picture-gallery .gallery-content .grid-item-height3:before {
  padding-top: 150%;
}

.nav-subscribe {
  background-color: #eaedf5;
}

.nav-subscribe-wrapper {
  height : 120px;
  width  : 100%;
  display: table;
}

.nav-subscribe-wrapper .nav-subscribe-left {
  display       : table-cell;
  vertical-align: middle;
  text-align    : left;
  color         : #434e6c;
}

.nav-subscribe-wrapper .nav-subscribe-left .subscribe-text {
  margin       : 0px;
  font-size    : 30px;
  padding-right: 30px;
}

.nav-subscribe-wrapper .nav-subscribe-left .focus {
  font-weight: bold;
}

.nav-subscribe-wrapper .nav-subscribe-right {
  display       : table-cell;
  vertical-align: middle;
  text-align    : right;
}

.page-title {
  background-image     : url('../../src/assets/images/banner-1.jpg');
  background-repeat    : repeat;
  background-attachment: fixed;
  background-position  : top;
  width                : 100%;
  height               : 300px;
  color                : #fff;
  text-align           : center;
}

.page-title .container {
  height : 100%;
  display: table;
}

.page-title .page-title-wrapper {
  display       : table-cell;
  vertical-align: middle;
  position      : relative;
  z-index       : 2;
}

.page-title .captions {
  font-size     : 42px;
  font-weight   : 900;
  text-transform: uppercase;
  line-height   : 1.2;
  margin        : 0 0 7px 0;
}

.page-title .breadcrumb {
  margin          : 0;
  background-color: transparent;
  text-transform  : capitalize;
}

.page-title .breadcrumb li {
  display: inline-block;
}

.page-title .breadcrumb li a {
  color: #cccccc;
}

.page-title .breadcrumb li a:hover {
  color: #3275bb;
}

.page-title .breadcrumb li:last-child {
  pointer-events: none;
}

.page-title .breadcrumb li.active a {
  color: #fff;
}

.page-title .breadcrumb li+li:before {
  padding    : 0 10px;
  color      : #cccccc;
  content    : '\f105';
  font-family: FontAwesome;
}

.search-input {
  background         : url('../../src/assets/images/background-search.png') no-repeat;
  background-color   : #3275bb;
  background-position: center;
  background-size    : cover;
  width              : 100%;
  height             : 100%;
}

.search-input .search-input-wrapper {
  padding: 60px 0;
}

.search-input .form-select,
.search-input .form-input,
.search-input .form-submit,
.search-input .sbHolder {
  height          : 40px;
  border          : 1px solid #fff;
  margin-right    : 10px;
  padding-left    : 20px;
  text-transform  : capitalize;
  background-color: #fff;
  float           : left;
  text-align      : left;
}

.search-input .form-select p i,
.search-input .form-input p i,
.search-input .form-submit p i,
.search-input .sbHolder p i {
  font-size  : 16px;
  position   : relative;
  top        : -2px;
  margin-left: 18px;
}

.search-input .form-select,
.search-input .form-submit,
.search-input .sbHolder {
  width  : 15%;
  display: inline-block;
}

.search-input .form-submit {
  margin          : 0;
  float           : right;
  text-align      : center;
  border          : none;
  text-transform  : uppercase;
  color           : #fff;
  background-color: #242c42;
  padding         : 0;
}

.news-page .news-page-wrapper {
  margin-top: 30px;
}

.news-page .news-page-wrapper .edugate-layout-1 {
  margin-top   : 0;
  margin-bottom: 90px;
}

.news-page .news-masonry .gird-item {
  width  : 33.3333%;
  float  : left;
  padding: 0 15px;
}

.news-page .news-masonry .gird-item .edugate-layout-3 {
  min-height: auto;
}

.news-page .news-masonry .gird-item .owl-nav .owl-prev,
.news-page .news-masonry .gird-item .owl-nav .owl-next {
  position        : absolute;
  top             : 50%;
  margin-top      : -15px;
  color           : #fff;
  background-color: #3275bb;
  width           : 30px;
  text-align      : center;
  height          : 30px;
  line-height     : 30px;
}

.news-page .news-masonry .gird-item .owl-nav .owl-prev {
  left: 0;
}

.news-page .news-masonry .gird-item .owl-nav .owl-next {
  right: -1px;
}

.news-page .news-masonry .gird-item .note-title {
  margin    : 30px 0 0 0;
  font-style: inherit;
}

.news-page .news-masonry .gird-item .note-title .main-quote {
  font-weight   : 700;
  font-size     : 20px;
  color         : #49575f;
  text-transform: inherit;
}

.news-page .news-masonry .gird-item .note-title .author-quote {
  padding-top: 8px;
  font-size  : 14px;
  color      : #6f8190;
}

.news-page .news-masonry .gird-item .note-title .author-quote span {
  font-size: 12px;
}

.news-page .pagination {
  margin-top: 0;
}

.courses .top-title {
  text-transform: capitalize;
  font-size     : 16px;
  line-height   : 25px;
  color         : #6f8190;
}

.courses .top-title b {
  font-weight: 900;
}

.courses .courses-wrapper {
  text-align: center;
}

.courses .style-grid .col-style {
  width        : 50%;
  padding      : 0 15px;
  float        : left;
  margin-bottom: 90px;
  transition   : all 0.5s ease-in-out;
}

.courses .style-list .col-style {
  width        : 100%;
  padding      : 0 15px;
  float        : left;
  margin-bottom: 90px;
  transition   : all 0.5s ease-in-out;
}

.courses .style-list .col-style .edugate-layout-2:before {
  width: 87%;
}

.courses .top-content {
  margin-bottom: 80px;
}

.courses .top-content .edugate-tabs {
  margin-bottom: 0;
}

.courses .pagination {
  margin: 0;
}

.courses .group-title-index {
  margin-bottom: 90px;
}

.page-404 {
  width          : 100%;
  height         : 100vh;
  position       : relative;
  background     : url(../../src/assets/images/bg-404.jpg) center no-repeat;
  background-size: cover;
}

.page-404 img {
  z-index : -1;
  position: absolute;
  height  : auto;
}

.page-404 .container {
  width  : 100%;
  height : 100%;
  display: table;
}

.wrapper-404 {
  display       : table-cell;
  vertical-align: middle;
  width         : 100%;
}

.wrapper-404 .title-404 {
  text-align: center;
}

.wrapper-404 .title-404 .signal {
  font-size    : 23px;
  margin-bottom: 5px;
}

.wrapper-404 .title-404 .sub {
  font-size: 14px;
}

.wrapper-404 .title-404 .warning {
  font-weight: 900;
  font-size  : 215px;
  color      : #3275bb;
  text-shadow: 2px 2px 12px #dedede;
  margin     : 0px;
}

.wrapper-404 .title-404 .btn-404 {
  margin          : auto;
  width           : 200px;
  height          : 50px;
  background-color: #e9edf5;
  color           : #fff;
  border          : none;
  font-weight     : 900;
  font-size       : 24px;
}

.contact-method .method-item {
  padding         : 50px 0px;
  text-align      : center;
  background-color: #fafafa;
  transition      : all 0.3s ease-in;
}

.contact-method .method-item i {
  color        : #3275bb;
  font-size    : 40px;
  margin-bottom: 45px;
}

.contact-method .method-item .sub {
  font-size     : 16px;
  margin-bottom : 35px;
  text-transform: uppercase;
}

.contact-method .method-item .detail {
  font-size: 14px;
}

.contact-method .method-item .detail p {
  margin: 0px;
}

.contact-method .method-item:hover {
  background-color: #fff;
  box-shadow      : 0 0 15px rgba(0, 0, 0, 0.15);
}

.bg-w-form {
  padding-top: 100px;
}

.bg-w-form .form-group {
  min-height: 100px;
}

.bg-w-form .form-group .sbHolder {
  background-color: #fafafa;
  border-radius   : 0px;
  height          : 50px;
  border          : none;
  box-shadow      : none;
}

.bg-w-form .form-group .sbHolder .sbSelector {
  height      : 50px;
  padding-left: 20px;
  line-height : 50px;
}

.bg-w-form .form-group .sbHolder .sbToggle {
  height               : 50px;
  background-position-y: -33px;
}

.bg-w-form .form-group .sbHolder:focus,
.bg-w-form .form-group .sbHolder:active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.bg-w-form .form-label {
  text-transform: uppercase;
  font-weight   : bold;
}

.bg-w-form .form-input {
  background-color: #fafafa;
  border-radius   : 0px;
  height          : 50px;
  border          : none;
  box-shadow      : none;
}

.bg-w-form .form-input:focus,
.bg-w-form .form-input:active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.bg-w-form .contact-question .form-input {
  min-height: 190px;
  max-width : 1140px;
}

.bg-w-form .warning-label {
  color         : #ff0000;
  font-size     : 11px;
  text-transform: inherit;
}

.bg-w-form .contact-submit {
  text-align: center;
}

.bg-w-form .contact-submit .btn-contact {
  margin-top: 30px;
}

.bg-w-form .highlight {
  color: #ff0000;
}

.contact-map {
  width : 100%;
  height: 500px;
}

.news-detail {
  color: #6f8190;
}

.news-detail p {
  line-height: 24px;
}

.news-detail span {
  color: #49575f;
}

.news-detail .info .item a:hover span {
  color: #3275bb;
}

.news-image {
  width        : 100%;
  margin-bottom: 20px;
}

.title-news {
  font-size  : 36px;
  font-weight: 900;
  color      : #49575f;
  margin     : 0 0 5px 0;
}

.news-content {
  margin: 40px 0px 100px;
}

.news-content .news-des p {
  font-size    : 18px;
  font-weight  : bold;
  margin-bottom: 40px;
}

.news-content .news-image-block {
  margin-right : 30px;
  margin-bottom: 30px;
}

.news-content .wide-text p {
  margin-bottom: 40px;
}

.news-content .text-block p {
  margin-bottom: 30px;
}

.news-content .text-block p:last-child {
  margin-bottom: 0px;
}

blockquote {
  position   : relative;
  border-left: 0px;
  margin     : 50px 12.5%;
  padding    : 10px 0px 10px 58px;
}

blockquote:before {
  position   : absolute;
  content    : '\f10d';
  font-family: 'FontAwesome';
  top        : 5px;
  left       : 0;
  font-size  : 30px;
  color      : #90a1ae;
}

blockquote .main-quote {
  font-weight: 700;
  font-style : italic;
  font-size  : 24px;
}

blockquote .sub-quote {
  font-style: italic;
  font-size : 16px;
  color     : #627484;
}

blockquote footer {
  text-align : right;
  font-weight: bold;
  color      : inherit;
}

blockquote footer:before {
  display: none;
}

blockquote footer small {
  color  : inherit;
  display: inline-block;
}

blockquote footer small:before {
  display: none;
}

.news-list {
  margin-bottom: 70px;
}

.list-expand-title {
  color        : #49575f;
  font-size    : 24px;
  font-weight  : bold;
  margin-bottom: 30px;
}

ul.list-detail {
  padding: 0px;
  margin : 0px;
}

ul.list-detail li {
  margin-bottom: 15px;
  display      : table;
}

ul.list-detail li a {
  display: table-cell;
  color  : #6f8190;
}

ul.list-detail li a:hover {
  color: #3275bb;
}

ul.list-detail li i {
  display    : table-cell;
  width      : 25px;
  color      : #3275bb;
  font-weight: bold;
  font-size  : 10px;
}

.news-tag ul li {
  display         : inline-block;
  background-color: #e9eef2;
  margin-right    : 15px;
  margin-bottom   : 10px;
  height          : 30px;
  line-height     : 30px;
  transition      : all 0.3s ease-in;
}

.news-tag ul li:first-child {
  background-color: #2c343b;
}

.news-tag ul li:first-child:hover {
  background-color: #2c343b;
}

.news-tag ul li:hover {
  background-color: #242c42;
}

.news-tag ul li:hover a {
  color: #fff;
}

.news-tag ul li a {
  color  : #2c343b;
  padding: 0px 15px;
  display: inherit;
}

.news-tag ul li i {
  color  : #fff;
  padding: 0px 15px;
}

.news-author {
  border-left        : 5px solid #eaedf5;
  padding-left       : 25px;
  height             : 160px;
  display            : -webkit-flex;
  -webkit-align-items: center;
  display            : -ms-flexbox;
  display            : flex;
  -webkit-align-items: center;
  -ms-flex-align     : center;
  align-items        : center;
  background-color   : #fafafa;
  margin-bottom      : 100px;
}

.news-author img.author-image {
  width        : 100px;
  height       : 100px;
  border-radius: 50%;
}

.news-author .author a {
  font-size  : 18px;
  font-weight: bold;
}

.news-author .position {
  color        : #242c42;
  margin-bottom: 10px;
}

.news-author .media-left {
  padding-right: 0px;
}

.news-author .media-body {
  padding-left: 30px;
}

.news-comment {
  margin-bottom: 100px;
}

.news-comment ul.comment-list-children {
  margin-left: 100px;
}

.news-comment li .media-left {
  padding: 0px 15px;
}

.news-comment li .media-left .media-image img {
  border-radius: 50%;
  width        : 70px;
  height       : 70px;
}

.news-comment li .media-body {
  padding: 0px 15px;
}

.news-comment li .media-body .reader a {
  font-size  : 16px;
  font-weight: bold;
}

.news-comment li .media-body .reply-comment {
  background-color: #e9eef2;
  width           : 60px;
  cursor          : pointer;
  line-height     : 25px;
  transition      : all 0.3s ease-in;
  color           : #2c343b;
  font-size       : 12px;
  padding         : 5px 13px;
}

.news-comment li .media-body .reply-comment:hover {
  color           : #fff;
  background-color: #3275bb;
}

.news-comment li .media-body .time {
  color    : #52616d;
  font-size: 13px;
}

.news-comment li .list-item {
  background-color: #fafafa;
  padding         : 30px 15px;
  margin-bottom   : 10px;
}

.news-comment li .list-item-children {
  background-color: #fafafa;
  padding         : 30px 15px;
  margin-bottom   : 10px;
}

.news-comment li.media {
  margin-top: 0px;
  border    : none;
  padding   : 0px;
}

.news-comment li .btn-submit span {
  color: #fff;
}

.news-comment .comment-box {
  margin-left: 100px;
}

.comment-write .comment-write-title {
  color: #2c3e50;
}

.comment-write .form-comment .form-group {
  margin-bottom: 30px;
}

.comment-write .form-comment .form-group .input-icon {
  position: relative;
}

.comment-write .form-comment .form-group .input-icon i {
  right      : 30px;
  position   : absolute;
  color      : #bcc3c9;
  line-height: 50px;
}

.comment-write .form-comment .form-input {
  background-color: #fafafa;
  border-radius   : 0px;
  height          : 50px;
  border          : none;
  box-shadow      : none;
}

.comment-write .form-comment .form-input:focus,
.comment-write .form-comment .form-input:active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.comment-write .form-comment textarea {
  min-height: 300px;
  max-width : 100%;
}

.comment-write .form-comment .contact-submit button span {
  color: #fff;
}

.underline {
  font-weight  : bold;
  font-size    : 30px;
  margin-bottom: 50px;
  color        : #49575f;
}

.underline::after {
  width           : 50px;
  height          : 5px;
  background-color: #3275bb;
  content         : '';
  position        : absolute;
  display         : block;
}

.comment-box {
  padding: 0px;
}

.comment-form {
  padding-top: 0px;
}

.comment-form .form-input {
  background-color: #fff;
}

.comment-form textarea {
  max-width: 587px;
}

.comment-form .form-group {
  min-height: 0px;
}

.best-staff.staff-list {
  background-image: none;
  background-color: #fff;
  color           : #49575f;
  position        : relative;
}

.best-staff.staff-list .group-title-index {
  color: #49575f;
}

.best-staff.staff-list .group-title-index .center-title {
  font-weight: bold;
}

.intro-edu {
  padding-top: 100px;
}

.intro-edu p {
  line-height: 24px;
}

.intro-edu .intro-title {
  font-size     : 30px;
  padding-top   : 50px;
  padding-bottom: 30px;
  font-weight   : 300;
  color         : #49575f;
}

.intro-edu .intro-title b {
  font-weight: 900;
}

.intro-edu .intro-content {
  color: #6f8190;
}

.intro-edu .intro-content p {
  margin-bottom: 30px;
}

.intro-edu img {
  width    : 100%;
  max-width: 400px;
}

.edu-ab {
  background-color: #eaedf5;
  color           : #49575f;
  text-align      : center;
}

.edu-ab .center-title {
  font-weight: 300;
}

.edu-ab .center-title b {
  font-weight: 900;
}

.edu-ab .top-title {
  text-transform: none;
}

.edu-ab .edu-ab-content ul {
  margin-bottom: 0;
}

.edu-ab .edu-ab-content ul li {
  text-align: -webkit-center;
  width     : 20%;
}

.edu-ab .edu-ab-content ul li .circle-icon {
  height          : 100px;
  width           : 100px;
  background-color: #fff;
  border-radius   : 50%;
  display         : block;
  margin          : 0 auto;
  vertical-align  : middle;
  position        : relative;
  z-index         : 2;
  transition      : all 0.3s ease-in;
}

.edu-ab .edu-ab-content ul li .circle-icon i {
  color      : #3275bb;
  line-height: 100px;
  transition : all 0.3s ease-in;
}

.edu-ab .edu-ab-content ul li .circle-icon:before {
  position        : absolute;
  content         : '';
  width           : 85%;
  height          : 2px;
  top             : 50%;
  right           : 105%;
  margin-top      : -1px;
  background-color: #d6d9e3;
}

.edu-ab .edu-ab-content ul li .circle-icon:after {
  position        : absolute;
  content         : '';
  width           : 85%;
  height          : 2px;
  top             : 50%;
  left            : 105%;
  margin-top      : -1px;
  background-color: #d6d9e3;
}

.edu-ab .edu-ab-content ul li .circle-icon:hover {
  background-color: #3275bb;
}

.edu-ab .edu-ab-content ul li .circle-icon:hover i {
  color: #fff;
}

.edu-ab .edu-ab-content ul li:first-child .circle-icon:before {
  display   : none;
  visibility: hidden;
}

.edu-ab .edu-ab-content ul li:last-child .circle-icon:after {
  display   : none;
  visibility: hidden;
}

.edu-ab .edu-ab-content ul li span {
  display    : -webkit-inline-flex;
  display    : -ms-inline-flexbox;
  display    : inline-flex;
  padding-top: 15px;
}

.edu-feature {
  color: #6f8190;
}

.edu-feature p {
  line-height: 24px;
}

.edu-feature .edu-feature-text {
  text-align   : left;
  margin-bottom: 30px;
}

.edu-feature .edu-feature-text .center-title {
  font-weight: 900;
  color      : #49575f;
  line-height: 36px;
}

.edu-feature .edu-feature-text .top-title {
  text-transform: none;
  font-weight   : 400;
}

.edu-feature .edu-feature-list {
  margin-bottom: 0px;
}

.edu-feature .edu-feature-list li {
  border-bottom: 1px solid #ecf0f1;
  box-shadow   : none;
}

.edu-feature .edu-feature-list li .col-item-1 {
  line-height: 38px;
}

.edu-feature .edu-feature-list li .col-item-2 {
  padding: 5px 5px 20px 35px;
}

.edu-feature .edu-feature-list li:last-child {
  border-bottom: none;
}

.edu-feature .edu-feature-list li a {
  color: inherit;
}

.edu-feature .edu-feature-list li a span {
  color: #242c42;
}

.edu-feature .edu-feature-list li i {
  color: #3275bb;
  width: 35px;
}

.edu-feature img {
  width    : 100%;
  max-width: 570px;
}

.course-title {
  margin-top: 0;
}

.course-info {
  margin-bottom: 30px;
}

.course-video {
  margin-bottom: 70px;
}

.course-video iframe {
  margin-bottom: 30px;
  width        : 100%;
  height       : 400px;
}

.course-video .video-btn button {
  padding: 0px 10px;
}

.course-video .video-btn button.video-btn-left i {
  margin-left : 0px;
  margin-right: 10px;
  color       : #fff;
  position    : relative;
  z-index     : 3;
}

.course-video .video-btn button.video-btn-right {
  margin-left: 5px;
}

.course-video .video-btn button.video-btn-right i {
  margin-right: 0px;
  margin-left : 10px;
  color       : #fff;
  position    : relative;
  z-index     : 3;
}

.course-des {
  margin-bottom: 50px;
}

.course-table {
  position: relative;
}

.edu-table-responsive {
  border-collapse: collapse;
  width          : 100%;
}

.edu-table-responsive tr {
  height             : 50px;
  background-color   : #fafafa;
  border-bottom-color: #fff;
}

.edu-table-responsive tr.heading-table {
  background-color: #90a1ae;
}

.edu-table-responsive tr.heading-content {
  border-top-color: #fff;
  background-color: #e9f1f7;
}

.edu-table-responsive th {
  vertical-align: middle;
  text-align    : center;
  display       : table-cell;
  text-transform: uppercase;
  color         : #fff;
  font-weight   : bold;
  border        : 1px solid #fff;
}

.edu-table-responsive td {
  display       : table-cell;
  color         : #6f8190;
  font-weight   : normal;
  vertical-align: middle;
  text-align    : center;
}

.edu-table-responsive td a {
  color: #6f8190;
}

.edu-table-responsive td a:hover {
  color: #3275bb;
}

.edu-table-responsive td.bold-color {
  color: #49575f;
}

.edu-table-responsive td.bolder-color {
  color: #242c42;
}

.edu-table-responsive td.green-color {
  color: #3275bb;
}

.edu-table-responsive td.heading-content {
  font-size  : 16px;
  font-weight: bold;
  text-align : left;
}

.edu-table-responsive td i.w20 {
  width: 20px;
}

.edu-table-responsive td i.w27 {
  width: 27px;
}

.edu-table-responsive td i.fa-caret-right {
  background-color: #3275bb;
  border-radius   : 50%;
  width           : 14px;
  height          : 14px;
  text-align      : center;
  line-height     : 14px;
  color           : #fff;
}

.edu-table-responsive td i.fa-caret-right :before {
  position: relative;
  right   : -1px;
}

.edu-table-responsive td i.fa-file-text {
  color: #3275bb;
}

.edu-table-responsive td i.mr18 {
  margin-right: 18px;
}

.edu-table-responsive td i.mr25 {
  margin-right: 16px;
}

.edu-table-responsive td .bg-yellow {
  background-color: #e2cf00;
  color           : #fff;
  display         : initial;
  padding         : 5px 9px;
}

.edu-table-responsive .col-1 {
  width     : 360px;
  min-width : 360px;
  text-align: left;
}

.edu-table-responsive .col-2,
.edu-table-responsive .col-3 {
  width    : 150px;
  min-width: 150px;
}

.edu-table-responsive .col-4 {
  width    : 180px;
  min-width: 180px;
}

.left {
  text-align  : left;
  padding-left: 30px;
}

/* .outer-container {
  position: absolute;
  top     : 0;
  left    : 0;
  right   : 0px;
} */

.inner-container {
  overflow: hidden;
}

.table-header {
  position: relative;
}

.table-header .edu-table-responsive .col-1 {
  text-align: center;
}

.table-body {
  overflow: auto;
}

.spacing-table {
  height          : 30px !important;
  background-color: #fff !important;
}

.spacing-table:hover {
  background-color: #fff !important;
}

.courses-detail p {
  line-height: 24px;
}

.rlp {
  background     : url(../../src/assets/images/bg-register-login.jpg) no-repeat center center;
  background-size: cover;
  height         : 100vh;
}

.rlp .container {
  width  : 100%;
  height : 100%;
  display: table;
}

.rlp .rlp-wrapper {
  color         : #49575f;
  display       : table-cell;
  width         : 770px;
  vertical-align: middle;
}

.page-login {
  min-height: 480px;
}

.page-register {
  min-height: 627px;
}

.rlp-table {
  text-align      : center;
  background-color: #fff;
  box-shadow      : 0 0 15px rgba(0, 0, 0, 0.3);
  padding         : 50px 100px;
}

.rlp-table .rlp-title {
  font-weight   : bold;
  font-size     : 24px;
  text-transform: uppercase;
  margin-bottom : 40px;
  margin-top    : 20px;
}

.rlp-table img.login {
  margin-bottom: 30px;
  display      : inline-block;
  width        : auto;
}

.rlp-table .rlp-form {
  padding-top  : 0px;
  text-align   : left;
  margin-bottom: 35px;
}

.rlp-table .rlp-form .col-md-12 {
  margin-bottom: 12px;
}

.rlp-table .rlp-form label {
  text-transform: uppercase;
}

.rlp-table .rlp-form input {
  border-radius: 0px;
  border       : none;
}

.rlp-table .rlp-form .help-block {
  color    : #ff0000;
  font-size: 10px;
}

.register-table {
  padding: 50px;
}

.register-table .register-form .col-md-6 {
  margin-bottom: 12px;
}

.profile-teacher .profile-teacher-wrapper {
  padding-right: 0;
  padding-left : 0;
}

.profile-teacher .profile-teacher-wrapper .teacher-info {
  padding      : 0;
  display      : table;
  margin-bottom: 70px;
}

.profile-teacher .profile-teacher-wrapper .teacher-info .staff-item2 {
  width  : 270px;
  padding: 30px 30px 45px;
  display: table-cell;
}

.profile-teacher .profile-teacher-wrapper .teacher-info .staff-item2 .staff-info .staff-avatar img {
  max-width: 209px;
}

.profile-teacher .profile-teacher-wrapper .teacher-info .staff-item2 .staff-info .staff-name {
  display: none;
}

.profile-teacher .profile-teacher-wrapper .teacher-info .staff-item2 .staff-socials a:last-of-type {
  margin-right: 0;
}

.profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des {
  display       : table-cell;
  vertical-align: top;
  padding-left  : 30px;
}

.profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des .title {
  text-transform: uppercase;
  font-size     : 30px;
  font-weight   : 700;
  color         : #49575f;
}

.profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des .subtitle {
  font-weight  : 700;
  color        : #6f8190;
  margin-bottom: 35px;
}

.profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des .content .content-detail {
  color        : #6f8190;
  margin-bottom: 35px;
}

.profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des .content .detail-list {
  -webkit-padding-start: 0;
  list-style-position  : inside;
}

.profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des .content .detail-list li {
  line-height  : 1;
  margin-bottom: 15px;
}

.profile-teacher .profile-teacher-wrapper .slider-logo .slider-logo-wrapper {
  padding: 0;
}

.teacher-skill {
  background-image     : url('../../src/assets/images/teacher-skill.png');
  background-repeat    : no-repeat;
  background-attachment: scroll;
  background-position  : center;
  width                : 100%;
  padding-bottom       : 100px;
  padding-top          : 70px;
  color                : #fff;
}

.teacher-skill .container {
  height: 100%;
}

.teacher-skill .teacher-skill-content {
  height       : 100%;
  padding-right: 0;
  padding-left : 0;
}

.teacher-skill .teacher-skill-wrapper {
  width     : 100%;
  text-align: center;
}

.teacher-skill .teacher-skill-wrapper .title {
  font-size     : 24px;
  text-transform: uppercase;
  font-weight   : 900;
  line-height   : 1;
  margin-bottom : 69px;
}

.teacher-skill .teacher-skill-wrapper .row .skill-level {
  margin-bottom: 45px;
  text-align   : left;
}

.teacher-skill .teacher-skill-wrapper .row .skill-level:last-child {
  margin-bottom: 0;
}

.teacher-skill .teacher-skill-wrapper .row .skill-level:nth-last-child(2) {
  margin-bottom: 0;
}

.teacher-skill .teacher-skill-wrapper .row .skill-level .skill-name {
  font-size     : 16px;
  text-transform: uppercase;
  line-height   : 1;
  margin-bottom : 10px;
  font-weight   : 700;
}

.teacher-skill .teacher-skill-wrapper .row .skill-level .progress {
  margin-bottom: 0;
  height       : 14px;
  border       : 2px solid #fff;
  border-radius: 7px;
  overflow     : inherit;
  width        : 87.719298%;
}

.teacher-skill .teacher-skill-wrapper .row .skill-level .progress .progress-bar {
  background-color         : #3275bb;
  position                 : relative;
  transition               : width 2s ease;
  border-bottom-left-radius: 7px;
  border-top-left-radius   : 7px;
}

.teacher-skill .teacher-skill-wrapper .row .skill-level .progress .progress-bar:after {
  position        : absolute;
  content         : ''attr(aria-valuenow) '%';
  width           : 30px;
  height          : 20px;
  background-color: #242c42;
  right           : -15px;
  top             : -28px;
}

.teacher-course .teacher-course-wrapper {
  padding-left : 0;
  padding-right: 0;
}

.teacher-course .teacher-course-wrapper .table-body .edu-table-responsive .col-1 {
  text-align : center;
  font-weight: 700;
}

.teacher-course .teacher-course-wrapper .table-body .edu-table-responsive .col-2 {
  padding-left: 30px;
  text-align  : left;
}

.teacher-course .teacher-course-wrapper .table-body .edu-table-responsive td {
  font-size: 16px;
  border   : 0px solid transparent;
}

.teacher-course .teacher-course-wrapper .table-body .edu-table-responsive tr:hover {
  background-color: #e9f1f7;
}

.teacher-course .teacher-course-wrapper .table-body .edu-table-responsive tr:hover span,
.teacher-course .teacher-course-wrapper .table-body .edu-table-responsive tr:hover a {
  font-weight: 700;
  color      : #49575f;
}

.teacher-course .teacher-course-wrapper .edu-table-responsive .col-1 {
  min-width: 70px;
  width    : 70px;
}

.teacher-course .teacher-course-wrapper .edu-table-responsive .col-2 {
  min-width: 555px;
  width    : 555px;
}

.teacher-course .teacher-course-wrapper .edu-table-responsive .col-3 {
  min-width: 230px;
  width    : 230px;
}

.teacher-course .teacher-course-wrapper .edu-table-responsive .col-4 {
  min-width: 315px;
  width    : 315px;
}

.teacher-course .teacher-course-wrapper .edu-table-responsive th:first-child {
  border-left-color: transparent;
}

.gallery-page .gallery-nav {
  text-align: center;
}

.gallery-page .grid:after {
  content: '';
  display: block;
  clear  : both;
}

.gallery-page .grid-item {
  overflow  : hidden;
  float     : left;
  margin    : 15px;
  transition: box-shadow 0.5s ease;
}

.gallery-page .grid-item img {
  position: absolute;
  left    : 0;
  right   : 0;
  top     : 0;
  bottom  : 0;
  margin  : auto;
}

.gallery-page .grid-item .link {
  position: absolute;
  height  : 100%;
  width   : 100%;
  display : table;
}

.gallery-page .grid-item .link .content {
  position        : relative;
  z-index         : 1;
  display         : table-cell;
  vertical-align  : middle;
  width           : 100%;
  height          : 100%;
  padding         : 15px;
  text-align      : center;
  background-color: #3275bb;
  opacity         : 0;
  transition      : all 0.5s ease;
}

.gallery-page .grid-item .link .content .content-title {
  color         : #fff;
  font-size     : 1.571em;
  font-weight   : 700;
  text-transform: capitalize;
  transition    : all 0.5s ease;
  display       : block;
}

.gallery-page .grid-item .link .content .content-edu {
  transition: all 0.5s ease;
  color     : #e9f0f4;
  display   : block;
}

.gallery-page .grid-item .link .content .content-edu .icons {
  margin-right: 10px;
}

.gallery-page .grid-item .link .wrapper {
  position: relative;
}

.gallery-page .grid-item:hover {
  box-shadow        : 0 0 15px rgba(0, 0, 0, 0.5);
  -moz-box-shadow   : 0 0 15px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.gallery-page .grid-item:hover .content {
  opacity         : 1;
  background-color: rgba(10, 15, 28, 0.7);
}

.gallery-page.gallery-mansonry .grid-item,
.gallery-page.gallery-3column .grid-item {
  width : 350px;
  height: 260px;
}

.gallery-page.gallery-mansonry .grid-item-height2,
.gallery-page.gallery-3column .grid-item-height2 {
  height: 550px;
}

.gallery-page.gallery-4column .grid-item {
  width : 255px;
  height: 190px;
}

.gallery-page .load-more {
  margin    : 50px 0 0 0;
  text-align: center;
}

.gallery-page .load-more .btn {
  width: 200px;
}

.accordion {
  margin-bottom: 0;
}

.accordion .panel {
  border-radius: 0;
  border       : 0;
  box-shadow   : none;
}

.accordion .panel+.panel {
  margin-top: 1px;
}

.accordion .panel.active {
  position  : relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.accordion .panel.active .panel-heading {
  background-color: #ffffff;
}

.accordion .panel.active .panel-heading .panel-title a {
  font-weight: bold;
  color      : #49575f;
}

.accordion .panel.active .panel-heading .panel-title a:after {
  font-family: Fontawesome;
  content    : '\f068';
  position   : absolute;
  top        : 0;
  right      : 30px;
}

.accordion .panel-heading {
  background-color: #fafafa;
  border-radius   : 0;
  padding         : 0;
}

.accordion .panel-heading .panel-title a {
  font-size  : 16px;
  color      : #6f8190;
  display    : block;
  height     : 47px;
  line-height: 47px;
  padding    : 0 30px;
  position   : relative;
}

.accordion .panel-heading .panel-title a:after {
  font-family: Fontawesome;
  content    : '\f067';
  position   : absolute;
  top        : 0;
  right      : 30px;
}

.accordion .panel-heading+.panel-collapse>.panel-body {
  border-top: 0;
  padding   : 15px 30px 30px;
}

.contact-main2 {
  padding-top: 0;
}

.contact-main2 .bg-w-form {
  padding-top: 0;
}

.event-count-down {
  padding-bottom: 0;
}

.events-grid .pagination {
  margin-top: 0;
}

.count-down-wrapper {
  background     : url('../../src/assets/images/banner-countdown.jpg') no-repeat;
  background-size: cover;
  width          : 100%;
  height         : 100%;
  position       : relative;
}

.count-down-wrapper:before {
  content         : '';
  position        : absolute;
  top             : 0;
  right           : 0;
  left            : 0;
  bottom          : 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.count-down-wrapper .count-down-content {
  position  : relative;
  padding   : 50px 0;
  text-align: center;
}

.count-down-wrapper .clock-count-down {
  width : 450px;
  margin: 0 auto 50px;
}

.event-count-down .group-title-index {
  color        : #ffffff;
  margin-bottom: 50px;
}

.block-time .text-time {
  background-color: #ffffff;
  padding         : 10px 0;
  margin-bottom   : 1px;
  text-transform  : uppercase;
  text-align      : center;
  color           : #49575f;
}

.block-time .number-time {
  background-color: #ffffff;
  padding         : 15px 0;
  text-align      : center;
  font-size       : 30px;
  font-weight     : 900;
  color           : #3275bb;
}

.event-detail-title {
  font-size    : 36px;
  font-weight  : 900;
  color        : #49575f;
  margin-top   : 0;
  margin-bottom: 40px;
}

.event-detail-section {
  padding-bottom: 0;
}

.event-detail-thumb {
  margin-bottom: 45px;
}

.event-detail-des-content {
  margin-bottom: 50px;
}

.courses-detail-wrapper {
  margin-bottom: 60px;
}

.event-detail-related .underline {
  margin-bottom: 90px;
}

.info-event-detail .item {
  display      : block;
  margin-right : 0;
  margin-bottom: 5px;
  color        : #6f8190;
}

.info-event-detail .item:last-child {
  margin-bottom: 0;
}

.info-event-detail .label-time {
  text-transform: uppercase;
  color         : #49575f;
  display       : block;
  font-weight   : 700;
  margin-bottom : 5px;
}

.info-event-detail .note-time-block {
  margin-bottom: 10px;
}

.info-event-detail .note-time,
.info-event-detail .address-info a {
  color: #6f8190;
}

.info-event-detail .note-time span,
.info-event-detail .address-info a span {
  margin-left: 10px;
  color      : #3275bb;
  font-size  : 12px;
}

.info-event-detail .note-time span:hover,
.info-event-detail .address-info a span:hover {
  color: #49575f;
}

.list-categories .top-content {
  margin-bottom: 90px;
}

html * {
  outline: 0 !important;
}

html,
body {
  min-height: 100%;
  overflow-x: hidden;
}

body {
  font-family     : 'Lato', Helvetica, Arial, sans-serif;
  font-size       : 14px;
  line-height     : 1.42857143;
  color           : #737373;
  background-color: #ffffff;
}

p,
a,
label,
span {
  font-size: 14px;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

a {
  transition: all 0.2s ease;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
}

h5 {
  font-weight: normal;
}

.customs-row {
  display: block;
  width  : 100%;
  clear  : both;
}

.theme-setting {
  position        : fixed;
  left            : -220px;
  top             : 30%;
  background-color: #ffffff;
  border          : 0;
  width           : 220px;
  z-index         : 9999;
  transition      : 0.3s left cubic-bezier(0.03, 0.88, 0.34, 0.97);
  box-shadow      : 2px 0 5px rgba(0, 0, 0, 0.2);
}

.theme-setting .theme-loading {
  position  : fixed;
  top       : 0;
  left      : 0;
  right     : 0;
  bottom    : 0;
  background: rgba(255, 255, 255, 0.3);
  z-index   : 99999;
  display   : none;
}

.theme-setting .theme-loading .theme-loading-content {
  position  : absolute;
  top       : 50%;
  left      : 50%;
  text-align: center;
}

.theme-setting .btn-theme-setting {
  width           : 40px;
  height          : 40px;
  line-height     : 40px;
  text-align      : center;
  background-color: #ffffff;
  position        : absolute;
  font-size       : 16px;
  color           : #ababae;
  z-index         : 9999;
  right           : -40px;
  top             : 0;
  box-shadow      : 3px 0 5px rgba(0, 0, 0, 0.2);
}

.theme-setting .content-theme-setting {
  width      : 220px;
  line-height: 40px;
  text-align : center;
  display    : inline-block;
}

.theme-setting .content-theme-setting .title {
  padding       : 20px 0;
  color         : #242c42;
  text-transform: uppercase;
  font-size     : 20px;
  font-weight   : 700;
  text-align    : center;
  margin        : 0;
}

.theme-setting .content-theme-setting ul {
  margin        : 0;
  padding-bottom: 15px;
}

.theme-setting .content-theme-setting ul li {
  width         : 24px;
  height        : 24px;
  border        : 0px solid #f0f0f0;
  vertical-align: middle;
  margin        : 0 7px;
  cursor        : pointer;
}

.theme-setting .content-theme-setting ul li[data-color='color-1'] {
  background-color: #3275bb;
}

.theme-setting .content-theme-setting ul li[data-color='color-2'] {
  background-color: #7d3f98;
}

.theme-setting .content-theme-setting ul li[data-color='color-3'] {
  background-color: #0f723a;
}

.theme-setting .content-theme-setting ul li[data-color='color-4'] {
  background-color: #2aacff;
}

.theme-setting .content-theme-setting ul li[data-color='color-5'] {
  background-color: #e91e63;
}

.theme-setting .content-theme-setting ul li[data-color='color-6'] {
  background-color: #2bde73;
}

.theme-setting .content-theme-setting ul li[data-color='color-7'] {
  background-color: #f7d417;
}

.theme-setting .content-theme-setting ul li[data-color='color-8'] {
  background-color: #50bcb6;
}

.theme-setting .content-theme-setting ul li[data-color='color-9'] {
  background-color: #ef5734;
}

.theme-setting .content-theme-setting ul li[data-color='color-10'] {
  background-color: #8d6cab;
}

.loading * {
  display: none;
}

.dots-loader:not(:required) {
  opacity                 : 1;
  overflow                : hidden;
  position                : absolute;
  left                    : 50%;
  top                     : 50%;
  margin-left             : -4px;
  margin-top              : -4px;
  text-indent             : -9999px;
  display                 : inline-block;
  width                   : 8px;
  height                  : 8px;
  background              : transparent;
  border-radius           : 100%;
  box-shadow              : #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  -webkit-animation       : dots-loader 5s infinite ease-in-out;
  animation               : dots-loader 5s infinite ease-in-out;
  -webkit-transform-origin: 50% 50%;
  transform-origin        : 50% 50%;
  -webkit-transform       : scale(1);
  transform               : scale(1);
  transition              : 0.3s all;
}

@-webkit-keyframes dots-loader {
  0% {
    box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }

  8.33% {
    box-shadow: #f86 14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }

  16.67% {
    box-shadow: #f86 14px 14px 0 7px, #fc6 14px 14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }

  25% {
    box-shadow: #f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }

  33.33% {
    box-shadow: #f86 -14px -14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px -14px 0 7px, #4ae -14px -14px 0 7px;
  }

  41.67% {
    box-shadow: #f86 14px -14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }

  50% {
    box-shadow: #f86 14px 14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }

  58.33% {
    box-shadow: #f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }

  66.67% {
    box-shadow: #f86 -14px -14px 0 7px, #fc6 -14px -14px 0 7px, #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }

  75% {
    box-shadow: #f86 14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }

  83.33% {
    box-shadow: #f86 14px 14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae 14px 14px 0 7px;
  }

  91.67% {
    box-shadow: #f86 -14px 14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }

  100% {
    box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }
}

@keyframes dots-loader {
  0% {
    box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }

  8.33% {
    box-shadow: #f86 14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }

  16.67% {
    box-shadow: #f86 14px 14px 0 7px, #fc6 14px 14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }

  25% {
    box-shadow: #f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }

  33.33% {
    box-shadow: #f86 -14px -14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px -14px 0 7px, #4ae -14px -14px 0 7px;
  }

  41.67% {
    box-shadow: #f86 14px -14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }

  50% {
    box-shadow: #f86 14px 14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }

  58.33% {
    box-shadow: #f86 -14px 14px 0 7px, #fc6 -14px 14px 0 7px, #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }

  66.67% {
    box-shadow: #f86 -14px -14px 0 7px, #fc6 -14px -14px 0 7px, #6d7 -14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }

  75% {
    box-shadow: #f86 14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px -14px 0 7px, #4ae 14px -14px 0 7px;
  }

  83.33% {
    box-shadow: #f86 14px 14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae 14px 14px 0 7px;
  }

  91.67% {
    box-shadow: #f86 -14px 14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }

  100% {
    box-shadow: #f86 -14px -14px 0 7px, #fc6 14px -14px 0 7px, #6d7 14px 14px 0 7px, #4ae -14px 14px 0 7px;
  }
}

.loaded .dots-loader {
  opacity          : 0;
  z-index          : -1;
  pointer-events   : none;
  -webkit-transform: scale(0);
  transform        : scale(0);
}

.body-2 {
  background-color: #fff;
  position        : fixed;
  z-index         : 1000;
  top             : 0;
  left            : 0;
  right           : 0;
  bottom          : 0;
  transition      : all 0.5s ease;
}

.body-2.loaded {
  transition: all 0.5s ease;
  visibility: hidden;
  opacity   : 0;
}

.edugate-content {
  display       : table-cell;
  vertical-align: top;
  text-align    : left;
}

.edugate-content .title {
  font-weight: 700;
  margin     : 0;
  color      : #49575f;
  display    : inline-block;
}

.edugate-content .title:hover {
  color: #3275bb;
}

.edugate-content .star-rating {
  margin-bottom: 20px;
}

.edugate-content .description {
  color      : #6f8190;
  display    : inline-block;
  width      : 100%;
  line-height: 24px;
}

.edugate-layout-1 {
  position        : relative;
  width           : 100%;
  display         : table;
  background-color: #fafafa;
  margin-top      : 90px;
  transition      : all 0.3s linear;
}

.edugate-layout-1 .edugate-image {
  width       : 48%;
  height      : 400px;
  position    : relative;
  top         : -30px;
  padding-left: 30px;
  display     : table-cell;
}

.edugate-layout-1 .edugate-image img {
  height    : 100%;
  width     : 100%;
  object-fit: cover;
}

.edugate-layout-1 .edugate-content {
  width  : 55%;
  padding: 50px 30px 30px 30px;
}

.edugate-layout-1 .edugate-content .title {
  font-size     : 30px;
  margin        : 0 0 6px 0;
  max-height    : 84px;
  overflow      : hidden;
  text-transform: capitalize;
}

.edugate-layout-1 .edugate-content .title:hover {
  /* color: #3275BB; */
  color: #3275bb;
}

.edugate-layout-1 .edugate-content .info {
  margin-bottom: 5px;
}

.edugate-layout-1 .edugate-content .info-more {
  margin-bottom: 25px;
}

.edugate-layout-1 .edugate-content .btn {
  position: absolute;
  right   : 30px;
  bottom  : 30px;
}

.edugate-layout-1 .edugate-content .description {
  height  : 130px;
  overflow: hidden;
}

.edugate-layout-1:hover {
  background-color: #fff;
  box-shadow      : 0 0 15px rgba(0, 0, 0, 0.15);
}

.edugate-layout-1:hover .edugate-content .title {
  color: #242c42;
}

.edugate-layout-1:hover .edugate-content .title:hover {
  color: #3275bb;
}

.col-md-9 .edugate-layout-1 .edugate-image {
  width : 48%;
  height: 300px;
}

.col-md-9 .edugate-layout-1 .edugate-content {
  padding: 20px 30px 30px 30px;
}

.col-md-9 .edugate-layout-1 .edugate-content .title {
  font-size : 20px;
  margin    : 0 0 6px 0;
  max-height: 56px;
  overflow  : hidden;
}

.col-md-9 .edugate-layout-1 .edugate-content .title:hover {
  color: #3275bb;
}

.col-md-9 .edugate-layout-1 .edugate-content .info {
  margin-bottom: 5px;
}

.col-md-9 .edugate-layout-1 .edugate-content .info-more {
  margin-bottom: 25px;
}

.col-md-9 .edugate-layout-1 .edugate-content .description {
  height  : 72px;
  overflow: hidden;
}

.edugate-layout-2 {
  position  : relative;
  width     : 100%;
  transition: all 0.3s linear;
}

.edugate-layout-2 .edugate-layout-2-wrapper {
  padding: 30px 0 30px 30px;
  display: table;
  width  : 100%;
}

.edugate-layout-2 .edugate-content {
  padding-right: 30px;
  position     : relative;
}

.edugate-layout-2 .edugate-content .title {
  font-size     : 18px;
  text-transform: capitalize;
  margin-bottom : 5px;
}

.edugate-layout-2 .edugate-content .title:hover {
  color: #3275bb;
}

.edugate-layout-2 .edugate-content .info {
  margin-bottom: 5px;
}

.edugate-layout-2 .edugate-content .info-more {
  margin-bottom: 8px;
}

.edugate-layout-2 .edugate-content .description {
  height  : 96px;
  overflow: hidden;
}

.edugate-layout-2 .edugate-content .btn {
  position   : absolute;
  left       : 50%;
  margin-left: -65px;
  bottom     : -50px;
}

.edugate-layout-2 .edugate-image {
  width   : 300px;
  height  : 225px;
  display : table-cell;
  position: relative;
  z-index : 1;
}

.edugate-layout-2 .edugate-image img {
  width : 100%;
  height: 100%;
}

.edugate-layout-2:before {
  position        : absolute;
  content         : '';
  width           : 90%;
  height          : 100%;
  top             : 0;
  left            : 0;
  background-color: #fafafa;
  border-left     : 5px solid #eaedf5;
  transition      : all 0.3s linear;
}

.edugate-layout-2:after {
  position        : absolute;
  z-index         : 2;
  content         : '';
  width           : 5px;
  top             : 0;
  left            : 0;
  height          : 0;
  background-color: #242c42;
  transition      : all 0.5s ease;
}

.edugate-layout-2:hover:before {
  background-color: #fff;
  box-shadow      : 0 0 15px rgba(0, 0, 0, 0.15);
}

.edugate-layout-2:hover:after {
  height: 100%;
}

.edugate-layout-2:hover .edugate-content .title {
  color: #242c42;
}

.edugate-layout-2:hover .edugate-content .title:hover {
  /* color: #3275BB; */
  color: #3275bb;
}

.edugate-layout-3 {
  width           : 100%;
  min-height      : 480px;
  background-color: #fafafa;
  margin-top      : 0;
  margin-bottom   : 90px;
  border-bottom   : 5px solid #eaedf5;
  position        : relative;
  transition      : all 0.3s linear;
}

.edugate-layout-3 .edugate-layout-3-wrapper {
  position: relative;
  padding : 0 30px 20px 30px;
  top     : -30px;
}

.edugate-layout-3 .edugate-content {
  padding-top: 25px;
}

.edugate-layout-3 .edugate-content .title {
  font-size     : 20px;
  margin        : 0 0 10px 0;
  text-transform: uppercase;
  max-height    : 56px;
  overflow      : hidden;
}

.edugate-layout-3 .edugate-content .title:hover {
  color: #3275bb;
}

.edugate-layout-3 .edugate-content .info {
  margin-bottom: 5px;
}

.edugate-layout-3 .edugate-content .info-more {
  margin-bottom: 8px;
}

.edugate-layout-3 .edugate-content .description {
  line-height  : 24px;
  margin-bottom: 18px;
  max-height   : 72px;
  overflow     : hidden;
}

.edugate-layout-3 .edugate-content .total-courses i,
.edugate-layout-3 .edugate-content .total-courses a {
  display       : inline-block;
  color         : #49575f;
  text-transform: capitalize;
}

.edugate-layout-3 .edugate-content .total-courses i {
  margin-right: 10px;
}

.edugate-layout-3 .edugate-content .total-courses a:hover {
  color: #3275bb;
}

.edugate-layout-3 .edugate-content .btn {
  margin    : 0 auto;
  margin-top: 40px;
  display   : block;
}

.edugate-layout-3:after {
  position        : absolute;
  z-index         : 2;
  content         : '';
  height          : 5px;
  bottom          : -5px;
  left            : 0;
  width           : 0;
  background-color: #242c42;
  transition      : all 0.5s ease;
}

.edugate-layout-3:hover {
  background-color: #fff;
  box-shadow      : 0 0 15px rgba(0, 0, 0, 0.15);
}

.edugate-layout-3:hover .edugate-content .title {
  color: #242c42;
}

.edugate-layout-3:hover .edugate-content .title:hover {
  color: #3275bb;
}

.edugate-layout-3:hover:after {
  width: 100%;
}

.edugate-layout-3.news-gird {
  margin-top   : 0;
  margin-bottom: 90px;
}

.edugate-layout-3.news-gird .edugate-content .title {
  text-transform: capitalize;
  margin-bottom : 5px;
}

.edugate-layout-3.news-gird .edugate-content .btn {
  margin-top: 0;
}

.news-masonry .edugate-layout-3 .edugate-content .description {
  max-height: 100%;
  overflow  : initial;
}

.edugate-video {
  width: 100%;
}

.edugate-audio {
  width           : 100%;
  height          : 70px;
  padding         : 20px 0;
  background-color: rgba(0, 0, 0, 0.92);
}

.edugate-audio-player {
  width           : 100%;
  height          : 70px;
  padding         : 20px 0;
  background-color: rgba(0, 0, 0, 0.92);
}

.edugate-video-player {
  width   : 100%;
  height  : 217px;
  position: relative;
}

.mejs-container {
  width     : 100% !important;
  background: transparent;
}

.mejs-container .mejs-controls {
  background: transparent;
}

.mejs-container .mejs-controls .mejs-time-rail span,
.mejs-container .mejs-controls .mejs-time-rail a {
  border-radius: 0;
}

.mejs-container .mejs-controls .mejs-time-rail .mejs-time-total {
  background: #000000;
}

.mejs-container .mejs-controls .mejs-time-rail .mejs-time-loaded {
  background: #444444;
}

.mejs-container .mejs-controls .mejs-time-rail .mejs-time-current {
  background: #ffffff;
}

.mejs-container .mejs-controls .mejs-time-rail .mejs-time-float {
  width: 40px;
}

.mejs-container .mejs-controls .mejs-time-rail .mejs-time-float-corner {
  left: 15px;
}

.mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
  border-radius: 0;
  background   : #ffffff;
}

.mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
  border-radius: 0;
  background   : #444444;
}

.layout-left {
  float: left;
}

.layout-right {
  float: right;
}


/* CSS FOR INPUT SEARCH FULL WIDTH */
.inp-search__full {
  width    : 100%;
  max-width: 900px;
}

.inp-search__full .ant-select-selector {
  border-radius: 20px !important;
}

.inp-search__full input {
  height: 100% !important;
}

.text-p {
  font-size : 15px;
  text-align: justify;
}

.text-p p {
  font-size: 15px;
}

.course-des p span {
  font-size: 15px;
}

.layout-pc {
  display: block;
}

.layout-mobile {
  display: none;
}

.card-tracking {
  border       : 1px solid #f0f0f0;
  border-radius: 8px;
  padding      : 8px 12px 0px;
  margin-bottom: 10px;
}

.card-tracking p {
  margin-bottom: 5px;
}

.green1 {
  color: #388e3c;
}

.warning1 {
  color: #f5c242;
}

.danger1 {
  color: #ea3323;
}

.purple1 {
  color: rgb(119, 42, 119);
}

.oranged1 {
  color: orangered;
}

.font-weight-500 {
  font-weight: 600;
}


.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content     : "" !important;
  margin-right: 0px !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display    : inline-block;
  margin-left: 6px;
  color      : #ff4d4f;
  font-size  : 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content    : '*' !important;
}

.ant-form-item-label>label::after {
  content: '' !important;
}

/* modal success course */
.ant-modal-confirm-body {
  flex-direction: column;
  align-items   : center;
}

.mess-content {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  margin-top    : 30px;
}

.grid-tag-program {
  margin-bottom        : 50px;
  display              : grid;
  grid-template-columns: 19.1% 19.1% 19.1% 19.1% 19.1%;
  gap                  : 1%;
}

.tag-program {
  border       : 1px solid #adadad;
  padding      : 8px 10px;
  text-align   : center;
  background   : #3275bb;
  color        : white;
  border-radius: 4px;
}

.tag-program:hover {
  background-color: rgb(20, 20, 20);
  cursor          : pointer;
}

.active-program {
  background-color: rgb(20, 20, 20);
}


@media screen and (max-width: 1024px) {
  .navigation {
    padding-right: 0;
  }

  .edugate-layout-1 .edugate-image {
    height: 335px;
  }

  .edugate-layout-1 .edugate-content {
    padding-top: 30px;
  }

  .col-md-9 .edugate-layout-1 .edugate-image {
    height: auto;
    top   : 30px;
  }

  .col-md-9 .edugate-layout-1 .edugate-image img {
    height: auto;
  }

  .col-md-9 .edugate-layout-1 .edugate-content {
    padding: 30px;
  }

  .col-md-9 .edugate-layout-1 .btn {
    position  : relative;
    top       : auto;
    right     : auto;
    bottom    : auto;
    float     : right;
    margin-top: 10px;
  }

  .news-page .news-page-wrapper .edugate-layout-1 {
    margin-bottom: 70px;
  }

  .best-staff .container {
    max-width: 860px;
  }

  .top-courses .group-btn-top-courses-slider {
    margin-top: 0;
  }

  .courses .style-grid .col-style {
    width: 100%;
  }

  .btn-list-grid {
    display: none;
  }

  .news-page .news-page-wrapper {
    margin-top: 0;
  }

  .widget .media .media-right a p {
    font-size: 13px;
  }

  .edu-ab .edu-ab-content ul li {
    width: 25%;
  }

  .staff-list .container {
    max-width: 860px;
  }

  .register-table {
    padding: 30px 50px;
  }

  .register-table .register-title {
    margin-top: 0;
  }

  .gallery-page.gallery-mansonry .grid-item,
  .gallery-page.gallery-3column .grid-item {
    width : 283px;
    height: 210px;
  }

  .gallery-page.gallery-mansonry .grid-item-height2,
  .gallery-page.gallery-3column .grid-item-height2 {
    height: 450px;
  }

  .gallery-page.gallery-4column .grid-item {
    width : 283px;
    height: 210px;
  }

  .edugate-layout-3 {
    min-height: 470px;
  }

  .edugate-layout-3 .edugate-content .description {
    max-height: 100px;
  }
}

@media screen and (max-width: 991px) {
  .col-2 {
    display      : inline-block;
    margin-bottom: 20px;
  }

  .method-item {
    margin-bottom: 15px;
  }

  .grid-tag-program {
    grid-template-columns: 24.2% 24.2% 24.2% 24.2%;
  }
}

@media (min-width: 768px) {
  .dropdown:hover .edugate-dropdown-menu-1 {
    display: block;
  }

  .dropdown:hover .edugate-dropdown-menu-1 li:hover>.edugate-dropdown-menu-2 {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  header .header-main .logo {
    line-height: 60px;
  }

  header .header-main .logo .header-logo img {
    max-width: 150px;
  }

  header .header-main .navigation .nav-links li .main-menu {
    line-height: 60px;
    margin     : 0 10px;
    font-size  : 12px;
  }

  header .header-main .edugate-dropdown-menu-1 {
    top: 65px;
  }

  /* header .header-topbar .group-sign-in {
    margin-left: 50px;
  } */

  .slider-banner-02 {
    background-position: -210px;
  }

  .choose-course .choose-course-wrapper .col-md-4.col-xs-6 {
    margin-bottom: 70px;
  }

  .choose-course .choose-course-wrapper .col-md-4.col-xs-6:nth-last-child(-n + 3) {
    margin-bottom: 70px;
  }

  .choose-course .choose-course-wrapper .col-md-4.col-xs-6:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }

  .accordion-faq .row .col-md-6 {
    margin-bottom: 70px;
  }

  .accordion-faq .row .col-md-6:last-child {
    margin-bottom: 0;
  }

  .choose-course-2 .choose-course-wrapper .col-md-4.col-xs-6:nth-last-child(-n + 3) {
    margin-bottom: 30px;
  }

  .choose-course-2 .choose-course-wrapper .col-md-4.col-xs-6:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }

  .why-choose-us .why-choose-us-wrapper-bottom i {
    font-size: 30px;
  }

  .why-choose-us .why-choose-us-wrapper-bottom a,
  .why-choose-us .why-choose-us-wrapper-bottom p,
  .why-choose-us .why-choose-us-wrapper-bottom span {
    padding-left: 10px;
  }

  .slider-talk-about-us {
    padding-bottom: 50px;
  }

  .slider-talk-about-us .peopel-item .peopel-comment {
    width  : 80%;
    padding: 30px 20px;
  }

  .slider-talk-about-us .group-btn-slider {
    position: relative;
    top     : 0;
  }

  .edugate-layout-1 .edugate-image {
    height: auto;
    top   : 30px;
  }

  .edugate-layout-1 .edugate-image img {
    height: auto;
  }

  .edugate-layout-1 .edugate-content {
    padding: 30px;
  }

  .edugate-layout-1 .edugate-content .title {
    font-size : 24px;
    max-height: 68px;
  }

  .edugate-layout-1 .edugate-content .description {
    height: 72px;
  }

  .edugate-layout-1 .edugate-content .btn {
    position  : relative;
    top       : auto;
    right     : auto;
    bottom    : auto;
    float     : right;
    margin-top: 10px;
  }

  .edugate-layout-3 {
    min-height: 480px;
  }

  .group-btn-slider .btn-prev,
  .group-btn-slider .btn-next {
    top        : auto;
    bottom     : 20px;
    width      : 40px;
    height     : 40px;
    line-height: 32px;
  }

  .group-btn-slider .btn-prev {
    left       : 50%;
    margin-left: -50px;
  }

  .group-btn-slider .btn-next {
    right       : 50%;
    margin-right: -50px;
  }

  .best-staff {
    padding-bottom: 150px;
  }

  .best-staff .group-btn-slider {
    position: relative;
    top     : 80px;
  }

  .best-staff .group-btn-slider .btn-prev,
  .best-staff .group-btn-slider .btn-next {
    top        : auto;
    bottom     : 20px;
    width      : 40px;
    height     : 40px;
    line-height: 32px;
  }

  .best-staff .group-btn-slider .btn-prev {
    left       : 50%;
    margin-left: -50px;
  }

  .best-staff .group-btn-slider .btn-next {
    right       : 50%;
    margin-right: -50px;
  }

  .pricing {
    position      : relative;
    padding-bottom: 100px;
  }

  .pricing .group-title-index {
    margin-bottom: 30px;
  }

  .pricing .pricing-wrapper .col-sm-4 {
    width: 100%;
  }

  .pricing .pricing-wrapper .pricing-widget {
    padding-top   : 30px;
    padding-bottom: 40px;
  }

  .pricing .group-btn-slider {
    display : block;
    position: relative;
    top     : 40px;
  }

  .courses .style-grid .col-style {
    margin-bottom: 70px;
  }

  .courses .top-content {
    margin-bottom: 50px;
  }

  .courses .top-content .result-output {
    margin-bottom: 30px;
  }

  .gallery-page.gallery-mansonry .grid-item,
  .gallery-page.gallery-3column .grid-item {
    width : 45.5%;
    height: 260px;
  }

  .gallery-page.gallery-mansonry .grid-item-height2,
  .gallery-page.gallery-3column .grid-item-height2 {
    height: 550px;
  }

  .gallery-page.gallery-4column .grid-item {
    width : 45.5%;
    height: 260px;
  }

  .homepage-03 .info-text2 {
    display: none;
  }

  .homepage-03 .info-text1 {
    float: left;
  }

  .homepage-03 .info-text3 {
    float: right;
  }

  header .header-main.homepage-03 .logo {
    display    : block;
    float      : left;
    line-height: 50px;
    width      : 125px;
  }

  header .header-main.homepage-03 .navigation {
    float: right;
  }

  .choose-course-3 .item-course {
    width: 50%;
  }

  .choose-course-3 .item-course:nth-last-child(-n + 3) {
    border-bottom: none;
  }

  .choose-course-3 .item-course:nth-child(3n-2) {
    border-left: none;
  }

  .choose-course-3 .item-course:nth-last-child(-n + 2) {
    border-bottom: 1px solid #e1e1e1;
  }

  .choose-course-3 .item-course-wrapper .icon-course {
    width: 90px;
  }

  .search-input .form-select,
  .search-input .form-submit,
  .search-input .sbHolder {
    width  : 20%;
    display: inline-block;
  }

  .search-input .form-submit span i {
    display: none;
  }

  .slider-banner {
    min-height: 568px;
  }

  .slider-banner .slider-item {
    min-height: 568px;
  }

  .slider-banner .slider-item .slider-2 {
    background-position: -210px;
  }

  .slider-banner .slider-item .slider-3 {
    background-position: -130px;
  }

  .news-page .news-masonry .gird-item {
    width: 50%;
  }

  .news-page .news-masonry .gird-item .owl-nav .owl-next {
    right: 0;
  }

  .customs-row {
    display: inline;
  }

  .course-video {
    margin-bottom: 30px;
  }

  .table-body {
    height: 500px;
  }

  blockquote {
    margin: 25px 10% 20px 10%;
  }

  ul.tag-widget {
    margin: 20px 0 0 0;
  }

  .footer-top .footer-top-wrapper {
    padding: 30px 0px;
  }

  .footer-top .footer-top-wrapper .footer-top-left {
    display: table-row;
  }

  .footer-top .footer-top-wrapper .footer-top-left .footer-top-focus {
    line-height  : 1;
    margin-bottom: 5px;
  }

  .footer-top .footer-top-wrapper .footer-top-left .footer-top-text {
    margin-bottom: 20px;
  }

  .footer-top .footer-top-wrapper .footer-top-right {
    display   : table-row;
    text-align: left;
  }

  .footer-main .footer-main-wrapper {
    padding: 70px 0 10px 0;
  }

  .footer-main .title-widget {
    padding-bottom: 25px;
  }

  .nav-subscribe-wrapper .nav-subscribe-left .subscribe-text {
    font-size: 25px;
  }

  .page-404 {
    background-position: -200px;
  }

  .intro-edu {
    padding-bottom: 100px;
  }

  .intro-edu .intro-edu-wrapper .col-md-5 {
    text-align: center;
  }

  .edu-ab .edu-ab-content ul li .circle-icon:after {
    width: 50%;
  }

  .edu-ab .edu-ab-content ul li .circle-icon:before {
    width: 50%;
  }

  .edu-feature .col-md-6:first-child {
    margin-bottom: 50px;
  }

  .edu-feature img {
    margin : 0 auto;
    display: block;
  }

  .sidebar {
    margin-top: 70px;
  }

  .edugate-dropdown-menu-1>li>a,
  .edugate-dropdown-menu-1>li span,
  .edugate-dropdown-menu-1>li p {
    line-height: 40px !important;
  }

  .carousel-indicators {
    display: none;
  }

  .slider-talk-about-us.style-2 .group-btn-slider {
    display: block;
  }

  .slider-talk-about-us.style-2 .group-btn-slider .btn-next,
  .slider-talk-about-us.style-2 .group-btn-slider .btn-prev {
    background-color: transparent;
    border-color    : rgba(0, 0, 0, 0.5);
    color           : rgba(0, 0, 0, 0.5);
    box-shadow      : none;
    background-image: none;
  }

  .teacher-skill {
    padding-bottom: 30px;
    padding-top   : 30px;
  }

  .teacher-skill .teacher-skill-wrapper .title {
    margin-bottom: 29px;
  }

  .teacher-skill .teacher-skill-wrapper .row .skill-level {
    margin-bottom: 20px;
    padding      : 0 30px;
  }

  .teacher-skill .teacher-skill-wrapper .row .skill-level:nth-last-child(2),
  .teacher-skill .teacher-skill-wrapper .row .skill-level:last-child {
    margin-bottom: 20px;
  }

  .teacher-course .teacher-course-wrapper .table-body {
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  header .header-main .logo {
    line-height: 60px;
  }

  header .header-main .logo .header-logo img {
    width: 150px;
  }

  header .header-main .navigation {
    width           : 50%;
    min-width       : 290px;
    text-align      : right;
    position        : absolute;
    right           : 0;
    background-color: #fff;
    top             : 65px;
    box-shadow      : 0 10px 20px rgba(0, 0, 0, 0.3);
  }

  header .header-main .navigation .nav-links li .main-menu {
    line-height: 50px;
    padding    : 0;
    margin     : 0 20px;
    font-size  : 14px;
    text-align : left;
  }

  header .header-main .navigation .nav-links li.active .main-menu {
    border-bottom: none;
  }

  header .header-main .navigation .nav-links li:hover .main-menu {
    background-color: transparent;
  }

  header .header-main .navigation .nav-links li:hover .main-menu:after {
    height: 0;
  }

  header .header-main .navigation .icons-dropdown {
    float      : right;
    line-height: 50px;
    width      : 35px;
    text-align : center;
  }

  header .header-main .navbar-heade {
    display: inline-block;
    width  : 100%;
  }

  header .header-main .edugate-dropdown-menu-1 {
    padding      : 0;
    text-align   : right;
    margin       : 0 20px 0 20px;
    border-bottom: 2px solid #3275bb !important;
  }

  header .header-main .edugate-dropdown-menu-1>li>.link-page {
    line-height  : 40px !important;
    margin       : 0;
    padding-right: 30px;
    text-align   : left;
  }

  header .header-main .edugate-dropdown-menu-1>li:hover {
    background-color: #fff;
  }

  header .header-main .edugate-dropdown-menu-1 li:last-child>a {
    border-bottom: 1px solid #3275bb;
  }

  header .header-main .edugate-dropdown-menu-2 {
    background-color: #fff;
    border-bottom   : none;
  }

  header .header-main .edugate-dropdown-menu-2>li>.link-page {
    line-height  : 40px;
    margin       : 0;
    text-align   : left;
    padding-right: 30px;
  }

  header .header-main .edugate-dropdown-menu-2 li:last-child>a {
    border-bottom: 1px solid #3275bb;
  }

  header .header-main.header-fixed .logo {
    line-height: 40px;
  }

  header .header-main.header-fixed .logo .header-logo img {
    width: 125px;
  }

  header .header-main.header-fixed .edugate-navbar {
    margin: 6px 0 0 0;
  }

  header .header-main.header-fixed .navigation {
    top: 45px;
  }

  header .header-main.homepage-03 .logo {
    width      : 150px;
    line-height: 60px;
  }

  header .header-main.homepage-03 .navigation .nav-links {
    width        : 100%;
    text-align   : right;
    margin-bottom: 0;
    float        : none !important;
  }

  header .header-main.homepage-03.header-fixed .logo {
    line-height: 45px;
  }

  header .header-main.homepage-03.header-fixed .logo .header-logo img {
    width: 125px;
  }

  header .header-main.homepage-03.header-fixed .edugate-navbar {
    margin: 6px 0 0 0;
  }

  header .header-main.homepage-03.header-fixed .navigation {
    top: 45px;
  }

  header .header-main.homepage-03.header-fixed .navigation .nav-links li .main-menu {
    line-height: 50px;
    padding    : 0 13px 0 10px;
    margin     : 0;
  }

  header .header-main.homepage-03.header-fixed .navigation .nav-links li.active .main-menu {
    border-bottom: none;
  }

  header .header-main.homepage-03.header-fixed .navigation .nav-links li:hover .main-menu {
    background-color: transparent;
  }

  header .header-main.homepage-03.header-fixed .navigation .nav-links li:hover .main-menu:after {
    height: 0;
  }

  header .header-topbar .socials {
    display: none;
  }

  header .dropdown-menu>li>a:hover,
  header .dropdown-menu>li>a:focus {
    background-color: #fff;
  }

  .navigation {
    padding-right: 15px;
  }

  .button-search {
    opacity: 0;
  }

  .nav-search {
    display   : block !important;
    box-shadow: none;
    padding   : 0;
    width     : 100%;
    position  : relative;
    top       : -50px;
    right     : 0;
    bottom    : 0;
  }

  .nav-search:after {
    position        : absolute;
    content         : '';
    width           : 100%;
    height          : 2px;
    background-color: #cccccc;
    left            : 0;
    right           : 0;
    top             : 0;
  }

  .nav-search form {
    border: none;
  }

  .nav-search form input[type='text'] {
    width  : 80%;
    padding: 0 5px 0 15px;
  }

  .nav-search form .searchbutton {
    width        : 20%;
    padding-right: 0;
    text-align   : center;
  }

  .edugate-navbar {
    background-color: #3275bb;
    cursor          : pointer;
    margin-top      : 16px;
  }

  .edugate-navbar .icon-bar {
    background-color: #fff;
  }

  .slider-banner .slider-item {
    height: 568px;
  }

  .slider-banner .slider-item .slider-caption {
    text-align: center;
  }

  .slider-banner-02 {
    background-position: center;
  }

  .slider-banner-03 .container {
    min-height: 568px;
  }

  .event-detail-content .row {
    margin: 0;
  }

  .event-detail-related .underline {
    margin-bottom: 60px;
  }

  .gallery-page.gallery-mansonry .grid-item,
  .gallery-page.gallery-3column .grid-item {
    width : 45.5%;
    height: 225px;
  }

  .gallery-page.gallery-mansonry .grid-item-height2,
  .gallery-page.gallery-3column .grid-item-height2 {
    height: 480px;
  }

  .gallery-page.gallery-4column .grid-item {
    width : 45.5%;
    height: 225px;
  }

  .count-down-wrapper .clock-count-down {
    margin-bottom: 20px;
  }

  .count-down-wrapper .clock-count-down .col-sm-3 {
    margin-bottom: 30px;
  }

  .progress-bars {
    position      : relative;
    padding-bottom: 160px;
  }

  .progress-bars .container {
    width: 100%;
  }

  .progress-bars .group-button {
    margin: 0;
  }

  .progress-bars .progress-bar-wrapper .group-btn-slider {
    display : block;
    position: relative;
    top     : 80px;
  }

  .edugate-layout-1 {
    margin-top   : 0;
    margin-bottom: 70px;
  }

  .edugate-layout-2:before {
    width: 80%;
  }

  .why-choose-us .why-choose-us-wrapper-top .why-choose-us-wrapper {
    padding-right: 35%;
  }

  .why-choose-us .why-choose-us-wrapper-top .background-girl-1 {
    width: 30%;
    left : 65%;
  }

  .why-choose-us .why-choose-us-wrapper-bottom .customs-row {
    display: block;
  }

  .slider-talk-about-us .peopel-item .peopel-comment {
    width: 90%;
  }

  .top-courses .top-courses-slider {
    margin-bottom: 0;
  }

  .top-courses .group-btn-top-courses-slider {
    margin-top: 30px;
  }

  .search-input {
    background-repeat: repeat;
  }

  .search-input .form-select,
  .search-input .form-submit {
    width: 22%;
  }

  .search-input .form-input {
    width       : 56% !important;
    margin-right: 0;
  }

  .search-input .form-submit {
    width     : 160px;
    text-align: center;
    margin    : 0 auto;
    margin-top: 10px;
    float     : none;
  }

  .search-input .search-input-wrapper {
    padding   : 45px 0;
    text-align: center;
  }

  .page-404 {
    min-height: 568px;
    height    : auto;
    display   : table;
  }

  .page-404 .container {
    display       : table-cell;
    vertical-align: middle;
  }

  .page-404 .wrapper-404 .title-404 .warning {
    line-height: 1.1;
  }

  .edugate-layout-3 {
    min-height   : auto;
    margin-bottom: 70px;
  }

  .edugate-layout-3 .edugate-layout-3-wrapper {
    top    : 0;
    padding: 30px;
    display: table;
  }

  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-image,
  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-video,
  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-audio {
    display       : table-cell;
    vertical-align: top;
    width         : 48%;
  }

  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-image .news-gird-slider,
  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-video .news-gird-slider,
  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-audio .news-gird-slider {
    width: 295px;
  }

  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-video,
  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-audio {
    min-width: 290px;
  }

  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-content {
    display       : table-cell;
    vertical-align: top;
    padding       : 0 0 0 30px;
  }

  .edugate-layout-3 .edugate-content .btn {
    position  : relative;
    top       : auto;
    right     : auto;
    bottom    : auto;
    float     : right;
    margin-top: 10px;
  }

  .edugate-layout-3.news-gird {
    margin-bottom: 70px;
  }

  .news-page .news-masonry .gird-item {
    width: 100%;
  }

  .list-categories .result-output {
    margin-bottom: 0;
  }

  .list-categories .top-content {
    margin-bottom: 70px;
  }

  .edu-ab .edu-ab-content ul li .circle-icon {
    height: 90px;
    width : 90px;
  }

  .edu-ab .edu-ab-content ul li .circle-icon i {
    line-height: 90px;
  }

  .register-table .register-title {
    font-size: 20px;
  }

  .footer-main .footer-main-wrapper {
    padding: 50px 0 0px 0;
  }

  .footer-main .hyperlink {
    line-height: 45px;
    min-height : 45px;
  }

  .courses-detail {
    padding-bottom: 70px;
  }

  .sidebar .widget:nth-last-child(-n + 3) {
    margin-bottom: 0;
  }

  .layout-left {
    float: none;
  }

  .layout-right {
    float: none;
  }

  .dropdown-focus {
    display         : block;
    position        : static;
    float           : none;
    width           : auto;
    margin-top      : 0;
    background-color: transparent;
    border          : 0;
    box-shadow      : none;
  }

  .courses .group-title-index {
    margin-bottom: 60px;
  }

  .edugate-video-player {
    width: 310px;
  }

  .edugate-audio-player {
    width: 310px;
  }
}


@media only screen and (max-width: 600px) {
  .layout-pc {
    display: none;
  }

  .layout-mobile {
    display: block;
  }

  .tracking-title {
    font-size     : 22px;
    padding-bottom: 20px !important;
  }

  .grid-tag-program {
    grid-template-columns: 32.5% 32.5% 32.5%;
  }
}

@media screen and (max-width: 600px) {
  header .header-topbar .group-sign-in {
    margin-left: 0;
  }

  .nav-search form input[type='text'] {
    width: 79%;
  }

  .nav-search form .searchbutton {
    width        : 20%;
    padding-right: 0;
  }

  .search-input .form-select,
  .search-input .form-submit,
  .search-input .sbHolder {
    min-width: 150px;
  }

  .search-input .form-input {
    width: 40% !important;
  }

  .slider-item .slider-caption .text-info-1 {
    font-size    : 46px;
    margin-bottom: 5px;
  }

  .slider-item .slider-caption .text-info-2 {
    font-size    : 26px;
    margin-bottom: 5px;
  }

  .slider-item .slider-caption .btn p {
    font-size  : 18px;
    line-height: 40px;
  }

  .slider-banner-02 .slider-banner-wrapper .sub-title,
  .slider-banner-03 .slider-banner-wrapper .sub-title {
    font-size    : 24px;
    margin-bottom: 20px;
  }

  .slider-banner-02 .slider-banner-wrapper .main-title,
  .slider-banner-03 .slider-banner-wrapper .main-title {
    font-size    : 60px;
    margin-bottom: 40px;
  }

  .section-padding {
    padding: 20px 0;
  }

  .bg-w-form {
    padding-top: 50px;
  }

  .picture-gallery .gallery-content .grid-item {
    width: 50%;
  }

  .event-count-down {
    padding-bottom: 0;
  }

  .contact-main2 {
    padding-top: 0;
  }

  .gallery-page .grid-item {
    margin: 0 7.5px 15px 7.5px;
    width : 46.5% !important;
  }

  .accordion .panel-heading .panel-title a {
    text-overflow: ellipsis;
    white-space  : nowrap;
    overflow     : hidden;
    width        : 100%;
    padding-right: 70px;
  }

  .gallery-page.gallery-mansonry .grid-item-height2,
  .gallery-page.gallery-mansonry .grid-item-height2 {
    height: 465px;
  }

  .edu-ab .edu-ab-content ul li .circle-icon:before {
    width: 45%;
  }

  .choose-course .name-course {
    margin-top: 15px;
  }

  .choose-course .choose-course-wrapper .col-md-4.col-xs-6 {
    margin-bottom: 40px;
  }

  .choose-course .choose-course-wrapper .col-md-4.col-xs-6:nth-last-child(-n + 3) {
    margin-bottom: 40px;
  }

  .choose-course .choose-course-wrapper .col-md-4.col-xs-6:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }

  .choose-course-3 .item-course-wrapper .icon-course {
    display   : block;
    width     : 100%;
    padding   : 0;
    text-align: center;
  }

  .choose-course-3 .item-course-wrapper .icon-course:after {
    display: none;
  }

  .choose-course-3 .item-course-wrapper .info-course {
    display    : block;
    width      : 100%;
    padding    : 0;
    text-align : center;
    padding-top: 15px;
  }

  .choose-course-3 .item-course {
    height: 300px;
  }

  .pricing {
    padding-bottom: 90px;
  }

  .pricing .pricing-wrapper .pricing-widget {
    padding-top: 0;
  }

  .best-staff {
    padding-bottom: 120px;
  }

  .progress-bars {
    padding-bottom: 120px;
  }

  .picture-gallery {
    padding-bottom: 0;
  }

  .footer-top .footer-top-wrapper .footer-top-left .footer-top-focus {
    font-size  : 24px;
    line-height: 30px;
  }

  .edugate-tabs {
    margin-bottom: 20px;
  }

  .edugate-tabs li {
    height: 60px;
  }

  .courses .top-content {
    margin-bottom: 20px;
  }

  .slider-talk-about-us .slider-talk-about-us-wrapper {
    padding: 60px 0;
  }

  .slider-talk-about-us .group-btn-slider {
    top: 20px;
  }

  .edugate-layout-3 .edugate-content .btn {
    position    : relative;
    bottom      : auto;
    right       : auto;
    margin-top  : 20px;
    margin-right: 0;
  }

  .underline {
    margin-bottom: 35px;
  }

  .course-title {
    font-size: 28px;
  }

  .news-list {
    margin-bottom: 30px;
  }

  .news-list .list-expand-title {
    margin-bottom: 20px;
  }

  .news-content {
    margin-bottom: 60px;
  }

  .news-author {
    margin-bottom: 60px;
  }

  .news-comment {
    margin-bottom: 60px;
  }

  .intro-edu {
    padding: 60px 0;
  }

  .rlp-table {
    padding: 30px 30px;
  }

  .sidebar {
    margin-top: 50px;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .staff-item {
    width: 220px;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .staff-item .staff-item-wrapper {
    height: 210px;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des .title {
    margin-bottom: 25px;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des .content .content-detail {
    margin-bottom: 20px;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info {
    display   : inline-block;
    text-align: center;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .staff-item2 {
    width        : 270px;
    padding      : 30px 30px 45px;
    margin-bottom: 20px;
    display      : inline-block;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des {
    display       : inline-block;
    vertical-align: middle;
    padding       : 0;
    text-align    : left;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des .title {
    margin-bottom: 5px;
  }

  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-video,
  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-audio {
    min-width: 210px;
  }
}

@media screen and (max-width: 480px) {
  .choose-course .icon-circle {
    width : 140px;
    height: 140px;
  }

  .choose-course .icon-circle .icon-background {
    width      : 116px;
    height     : 116px;
    margin-left: -58px;
  }

  .choose-course .icon-circle .icon-background .icons-img {
    font-size: 58px;
  }

  .progress-bars .title-2 {
    font-size  : 24px;
    line-height: 34px;
  }

  .slider-banner-02 {
    background-position: left;
  }

  .why-choose-us .why-choose-us-wrapper-top .why-choose-us-wrapper {
    padding-right: 0;
  }

  .why-choose-us .why-choose-us-wrapper-top .background-girl-1 {
    display: none;
  }

  .why-choose-us .why-choose-us-wrapper-bottom .why-choose-us-wrapper {
    padding: 20px 0;
  }

  .why-choose-us .why-choose-us-wrapper-bottom .section-icon {
    width : 100%;
    margin: 15px 0;
  }

  .why-choose-us .why-choose-us-wrapper-bottom .section-icon i {
    width: 50px;
  }

  .accordion-faq .row .col-md-6 {
    margin-bottom: 40px;
  }

  .slider-talk-about-us .peopel-item .peopel-comment {
    width: 100%;
  }

  .staff-item {
    padding: 20px 15px;
  }

  .staff-item .staff-item-wrapper {
    padding: 0 15px;
  }

  .edugate-layout-2:before {
    width: 100%;
  }

  .edugate-layout-2 .edugate-layout-2-wrapper {
    padding: 30px;
  }

  .edugate-layout-2 .edugate-layout-2-wrapper .edugate-content {
    display      : block;
    padding      : 0;
    width        : 100%;
    margin-bottom: 30px;
  }

  .edugate-layout-2 .edugate-layout-2-wrapper .edugate-content .title {
    font-size: 24px;
  }

  .edugate-layout-2 .edugate-layout-2-wrapper .edugate-image {
    width  : 100%;
    height : auto;
    display: block;
  }

  .edugate-layout-2 .edugate-content .btn {
    position     : inherit;
    left         : auto;
    margin-left  : auto;
    bottom       : auto;
    float        : right;
    margin-bottom: 30px;
    margin-top   : 10px;
  }

  .group-title-index {
    margin-bottom: 30px;
  }

  .pricing {
    padding-bottom: 80px;
  }

  .pricing .group-title-index {
    margin-bottom: 10px;
  }

  .edugate-layout-1 .edugate-image {
    display: block;
    width  : 100%;
    padding: 30px;
    top    : 0;
  }

  .edugate-layout-1 .edugate-content {
    width  : 100%;
    display: block;
    padding: 0 30px;
  }

  .edugate-layout-1 .edugate-content button {
    margin-bottom: 30px;
    margin-top   : 10px;
  }

  .col-md-9 .edugate-layout-1 .edugate-image {
    width: 100%;
    top  : 0;
  }

  .col-md-9 .edugate-layout-1 .edugate-content {
    padding: 0 30px;
  }

  .news-page .news-page-wrapper .edugate-layout-1 {
    margin-bottom: 40px;
  }

  .count-down-wrapper .clock-count-down {
    padding-left : 15px;
    padding-right: 15px;
    width        : 100%;
  }

  .slider-banner-02 .slider-banner-wrapper .sub-title,
  .slider-banner-03 .slider-banner-wrapper .sub-title {
    font-size    : 20px;
    margin-bottom: 15px;
  }

  .slider-banner-02 .slider-banner-wrapper .main-title,
  .slider-banner-03 .slider-banner-wrapper .main-title {
    font-size    : 40px;
    margin-bottom: 30px;
  }

  .choose-course-2 .col-md-4.col-xs-6 :nth-child(2n - 1) {
    padding-right: 8px;
  }

  .choose-course-2 .col-md-4.col-xs-6 :nth-child(2n) {
    padding-left: 7px;
  }

  .choose-course-2 .item-course {
    padding: 55px 0;
  }

  .choose-course-2 .item-course .info-course {
    margin-top: 10px;
    padding   : 0 10px;
  }

  .choose-course-2 .item-course .info-course .name-course {
    font-size    : 20px;
    margin-bottom: 5px;
  }

  .choose-course-2 .item-course .hover-text .wrapper-hover-content {
    padding: 10px;
  }

  .choose-course-3 .item-course {
    height: 270px;
  }

  .courses .style-grid .col-style {
    margin-bottom: 40px;
  }

  .top-courses .top-courses-item {
    padding: 20px 0;
  }

  .courses .group-title-index {
    margin-bottom: 60px;
  }

  .search-input .form-select,
  .search-input .form-submit,
  .search-input .sbHolder {
    width        : 47%;
    margin-bottom: 10px;
    margin-left  : 6px;
    margin-right : 6px;
  }

  .search-input .form-select.style-2,
  .search-input .form-submit.style-2,
  .search-input .sbHolder.style-2 {
    margin-right: 0;
  }

  .search-input .form-input {
    width       : 100% !important;
    margin-right: 0;
  }

  .search-input .form-submit {
    width     : 160px;
    text-align: center;
    margin    : 0 auto;
    margin-top: 10px;
    float     : none;
  }

  .search-input .search-input-wrapper {
    padding: 15px 0;
  }

  .wrapper-404 .title-404 .warning {
    font-size: 160px;
  }

  .wrapper-404 .title-404 .btn-404 {
    width    : 160px;
    height   : 40px;
    font-size: 18px;
  }

  .wrapper-404 .title-404 .btn-404 p {
    font-size: 18px;
  }

  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-image {
    display      : block;
    width        : 100%;
    margin-bottom: 30px;
  }

  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-content {
    display       : block;
    vertical-align: top;
    padding       : 0;
    width         : 100%;
  }

  .edugate-layout-3 .btn {
    position: absolute;
    bottom  : 30px;
    right   : 30px;
  }

  .underline {
    margin-bottom: 25px;
  }

  blockquote {
    padding-left: 40px;
    margin      : 20px 20px 10px 20px;
  }

  .course-des {
    margin-bottom: 30px;
  }

  .news-content {
    margin-bottom: 40px;
  }

  .news-author {
    margin-bottom: 40px;
  }

  .news-comment {
    margin-bottom: 40px;
  }

  .btn-submit {
    line-height: 25px;
  }

  .btn-submit p {
    font-size: 12px;
  }

  .socials a {
    margin-right: 6px;
  }

  .edu-ab .edu-ab-content ul li {
    width  : 280px;
    display: block;
    margin : 0 auto;
    padding: 40px 0;
  }

  .edu-ab .edu-ab-content ul li:first-child {
    padding-top: 20px;
  }

  .edu-ab .edu-ab-content ul li:last-child {
    padding-bottom: 0;
  }

  .edu-ab .edu-ab-content ul li .circle-icon {
    width  : 100px;
    height : 100px;
    display: table-cell;
  }

  .edu-ab .edu-ab-content ul li .circle-icon:after {
    width      : 2px;
    height     : 50px;
    top        : auto;
    margin-top : auto;
    left       : 50%;
    margin-left: -1px;
    bottom     : -53px;
  }

  .edu-ab .edu-ab-content ul li .circle-icon:before {
    width      : 2px;
    height     : 50px;
    margin-top : auto;
    left       : 50%;
    margin-left: -1px;
    top        : -53px;
  }

  .edu-ab .edu-ab-content ul li .circle-icon i {
    line-height: 100px;
  }

  .edu-ab .edu-ab-content ul li span {
    width         : 180px;
    display       : table-cell;
    vertical-align: middle;
    padding-left  : 20px;
    text-align    : left;
  }

  .nav-subscribe-wrapper {
    height : auto;
    padding: 25px 0 30px 0;
  }

  .nav-subscribe-wrapper .nav-subscribe-left,
  .nav-subscribe-wrapper .nav-subscribe-right {
    display   : table-row;
    text-align: left;
  }

  .nav-subscribe-wrapper .nav-subscribe-left .subscribe-text {
    padding      : 0;
    font-size    : 24px;
    margin-bottom: 12px;
    line-height  : 24px;
  }

  .group-title-index .center-title {
    font-size: 24px;
  }

  .rlp-table .rlp-title {
    font-size: 20px;
  }

  .news-comment ul.comment-list-children {
    margin-left: 50px;
  }

  .news-comment li .media-left {
    padding-left: 0px;
  }

  .news-comment li .media-body {
    padding-right: 0px;
  }

  .news-comment li .des p {
    font-size: 13px;
  }

  .title-news {
    font-size: 30px;
  }

  .news-content .news-image-block {
    margin-right : 0px;
    width        : 100%;
    margin-bottom: 40px;
  }

  .news-author {
    height: auto;
  }

  .news-author .des p {
    font-size: 13px;
  }

  .footer-main .footer-main-wrapper {
    padding: 50px 0 0 0;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .staff-item .staff-item-wrapper {
    height: 180px;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info {
    display   : inline-block;
    text-align: center;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .staff-item2 {
    width        : 270px;
    padding      : 30px 30px 45px;
    margin-bottom: 20px;
    display      : inline-block;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des {
    display       : inline-block;
    vertical-align: middle;
    padding       : 0;
    text-align    : left;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des .title {
    margin-bottom: 5px;
  }

  .edugate-video-player {
    width        : 360px;
    height       : 270px;
    margin-bottom: 15px;
  }

  .edugate-audio-player {
    width        : 360px;
    margin-bottom: 25px;
  }

  .grid-tag-program {
    grid-template-columns: 49.5% 49.5%;
  }
}

@media screen and (max-width: 420px) {

  header .header-topbar .email,
  header .header-topbar .hotline {
    display     : inherit;
    margin-right: 0;
    line-height : 25px;
  }

  .edugate-video-player {
    width : 290px;
    height: 230px;
  }

  .edugate-audio-player {
    width: 290px;
  }

  .sd380 {
    width: 100%;
  }

  .sidebar .widget:nth-last-child(-n + 3) {
    margin-bottom: 30px;
  }

  .sidebar .widget:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .staff-item .staff-item-wrapper {
    height: 160px;
  }

  .accordion-faq .underline,
  .contact-main2 .underline {
    font-size: 26px;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info {
    display   : inline-block;
    text-align: center;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .staff-item2 {
    width        : 270px;
    padding      : 30px 30px 45px;
    margin-bottom: 20px;
    display      : inline-block;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des {
    display       : inline-block;
    vertical-align: middle;
    padding       : 0;
    text-align    : left;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des .title {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 320px) {
  .edugate-video-player {
    width : 215px;
    height: 170px;
  }

  .edugate-audio-player {
    width: 215px;
  }

  .group-title-index .center-title {
    margin-bottom: 5px;
  }

  .choose-course .icon-circle {
    width : 120px;
    height: 120px;
  }

  .choose-course .icon-circle .icon-background {
    width      : 104px;
    height     : 104px;
    margin-left: -52px;
    bottom     : 8px;
  }

  .choose-course .icon-circle .icon-background .icons-img {
    font-size: 52px;
  }

  .choose-course .icon-circle .info .info-back:before {
    /* border: 8px solid #3275BB; */
    border: 8px solid #3275bb;
  }

  .edugate-layout-2 .edugate-layout-2-wrapper {
    padding: 20px;
  }

  .top-courses {
    padding-bottom: 40px;
  }

  .top-courses .group-btn-top-courses-slider {
    margin-top: 15px;
  }

  .edugate-layout-1 .edugate-image {
    padding: 20px;
  }

  .edugate-layout-1 .edugate-content {
    padding: 0 20px;
  }

  .edugate-layout-1 .edugate-content button {
    margin-bottom: 20px;
  }

  .col-md-9 .edugate-layout-1 .edugate-content {
    padding: 0 20px;
  }

  .picture-gallery .gallery-content .grid-item {
    width : 90%;
    margin: auto 15px 15px;
  }

  .edugate-layout-3 .edugate-layout-3-wrapper {
    padding: 20px;
  }

  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-image {
    margin-bottom: 20px;
  }

  .page-title .captions {
    font-size: 28px;
  }

  .course-video .video-btn button.video-btn-right {
    margin-bottom: 10px;
  }

  .footer-top-wrapper .footer-top-left .footer-top-focus {
    font-size: 23px;
  }

  .rlp-table {
    padding: 20px;
  }

  .rlp-table img.login {
    width        : 160px;
    margin-bottom: 0px;
  }

  .title-news {
    font-size: 24px;
  }

  .news-detail .info a,
  .news-detail .info span {
    font-size: 13px;
  }

  .news-content .news-des p {
    font-size: 14px;
  }

  blockquote .main-quote {
    font-size    : 20px;
    margin-bottom: 5px;
  }

  blockquote .sub-quote {
    font-size: 14px;
  }

  .footer-main .hyperlink .hyper-left {
    display: none;
  }

  p.stars a.star-1,
  p.stars a.star-2,
  p.stars a.star-3,
  p.stars a.star-4,
  p.stars a.star-5 {
    margin-right: 0.5em;
  }

  p.stars a.star-1 {
    width: 1.5em;
  }

  p.stars a.star-2 {
    width: 2.5em;
  }

  p.stars a.star-3 {
    width: 3.5em;
  }

  p.stars a.star-4 {
    width: 4.5em;
  }

  p.stars a.star-5 {
    width: 5.5em;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .staff-item .staff-item-wrapper {
    height: 90px;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .staff-item .staff-info .staff-name {
    font-size: 9px;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .staff-item .staff-socials a {
    margin-right: 9px;
    width       : 20px;
    height      : 20px;
    line-height : 20px;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info {
    display   : inline-block;
    text-align: center;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .staff-item2 {
    width        : 270px;
    padding      : 30px 30px 45px;
    margin-bottom: 20px;
    display      : inline-block;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des {
    display       : inline-block;
    vertical-align: middle;
    padding       : 0;
    text-align    : left;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des .title {
    margin-bottom: 5px;
  }

  .edugate-layout-3 .edugate-layout-3-wrapper .edugate-image .news-gird-slider {
    width: 215px;
  }
}

@media screen and (max-width: 380px) {
  .edugate-video-player {
    width : 250px;
    height: 190px;
  }

  .edugate-audio-player {
    width: 255px;
  }

  header .header-main .navigation {
    width: 100%;
    top  : 45px;
  }

  header .header-main .logo {
    line-height: 40px;
  }

  header .header-main .logo .header-logo img {
    width: 125px;
  }

  header .header-main.header-fixed .logo {
    line-height: 40px;
  }

  header .header-main.header-fixed .logo .header-logo img {
    width: 125px;
  }

  header .header-main .edugate-navbar {
    margin: 6px 0 0 0;
  }

  header .header-main.homepage-03 .logo {
    width      : 125px !important;
    line-height: 45px;
  }

  .choose-course .name-course a,
  .choose-course .name-course p,
  .choose-course .name-course span {
    font-size: 14px;
  }

  .choose-course .name-course i {
    display: none;
  }

  .progress-bars .progress-bar-wrapper .inner {
    font-size    : 50px;
    margin-bottom: 40px;
  }

  .progress-bars .progress-bar-wrapper .title-2 {
    margin-bottom: 30px;
  }

  .event-detail-content .col-md-4 {
    padding: 0;
  }

  .event-detail-title {
    font-size: 30px;
  }

  .choose-course-2 .choose-course-wrapper .col-md-4.col-xs-6:nth-last-child(-n + 2) {
    margin-bottom: 30px;
  }

  .choose-course-2 .choose-course-wrapper .col-md-4.col-xs-6:last-child {
    margin-bottom: 0;
  }

  .group-button button:first-child {
    margin-right : 0;
    margin-bottom: 20px;
  }

  .edugate-tabs {
    margin-bottom: 0px;
  }

  .slider-talk-about-us .peopel-item .peopel-comment {
    height  : 210px;
    overflow: hidden;
  }

  .edugate-content .title {
    line-height: 28px;
  }

  .choose-course-2 .col-md-4.col-xs-6 {
    width  : 100%;
    padding: 0 15px !important;
  }

  header .header-main.homepage-03 .navigation {
    top: 45px;
  }

  .choose-course-3 .item-course {
    width : 90%;
    margin: 15px auto 0 15px;
    border: 1px solid #e1e1e1 !important;
  }

  .search-input .form-select,
  .search-input .form-submit,
  .search-input .sbHolder {
    width        : 100%;
    margin       : 0;
    margin-bottom: 10px;
  }

  .search-input .form-input {
    width       : 100% !important;
    margin-right: 0;
  }

  .search-input .form-submit {
    width     : 160px;
    text-align: center;
    margin    : 0 auto;
    margin-top: 10px;
    display   : inline-block;
    float     : none;
  }

  .search-input .search-input-wrapper {
    padding: 15px 0;
  }

  .gallery-page .grid-item {
    width        : 100% !important;
    margin       : 0;
    margin-bottom: 15px;
  }

  .list-categories .top-content {
    margin: 0;
  }

  .courses .group-title-index {
    margin-bottom: 45px;
  }

  .top-content .result-output .result-count {
    display: none;
  }

  .pagination .btn-squae {
    width      : 32px;
    height     : 32px;
    line-height: 32px;
  }

  .pagination .pagination__list li .pagination__previous,
  .pagination .pagination__list li .pagination__next {
    line-height: 27px;
  }

  .edugate-layout-3 {
    margin-top   : 0;
    margin-bottom: 40px;
  }

  .edugate-layout-3.news-gird {
    margin-bottom: 40px;
  }

  .news-list .col-xs-6 {
    width: 100%;
  }

  .edugate-layout-1 {
    margin-bottom: 40px;
  }

  .course-title {
    font-size: 24px;
  }

  .course-info {
    margin-bottom: 30px;
  }

  .courses-detail {
    padding-bottom: 20px;
  }

  .page-title .captions {
    font-size: 34px;
  }

  .news-comment li .list-item {
    padding: 20px 15px;
  }

  .sd380 {
    width: 100%;
  }

  .rlp-table {
    padding: 20px;
  }

  .rlp-table img.login {
    width        : 180px;
    margin-bottom: 10px;
  }

  .rlp-table .rlp-title {
    font-size: 16px;
  }

  .col-2 {
    margin-bottom: 0;
  }

  .register-table .register-title {
    margin-top: 20px;
  }

  .news-comment ul.comment-list-children {
    margin-left: 25px;
  }

  .news-comment li .media-left {
    padding-right: 5px;
  }

  .news-comment li .media-body {
    padding-left: 5px;
  }

  .title-news {
    font-size    : 24px;
    line-height  : 26px;
    margin-bottom: 10px;
    display      : inline-block;
  }

  .news-content {
    margin-top: 30px;
  }

  .news-content .news-des p {
    margin-bottom: 30px;
    font-size    : 14px;
  }

  .news-detail .info a,
  .news-detail .info span {
    font-size: 13px;
  }

  .news-author .author a {
    font-size: 16px;
  }

  .news-author .position {
    font-size: 13px;
  }

  .media .media-right span {
    font-size: 12px !important;
  }

  .news-content .news-des p {
    font-size: 16px;
  }

  .footer-main .footer-main-wrapper {
    padding: 50px 0 0 0;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info {
    display   : inline-block;
    text-align: center;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .staff-item2 {
    width        : 270px;
    padding      : 30px 30px 45px;
    margin-bottom: 20px;
    display      : inline-block;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des {
    display       : inline-block;
    vertical-align: middle;
    padding       : 0;
    text-align    : left;
  }

  .profile-teacher .profile-teacher-wrapper .teacher-info .teacher-des .title {
    margin-bottom: 5px;
  }
}