/* CSS FOR SWIPER SLIDE BANNER*/
.swiper {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size : 18px;

  /* Center slide text vertically */
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.slider-banner .slider-item {
  width : 100%;
  height: calc(100vh - 155px);
}

.navigation-mobile {
  width           : 50%;
  min-width       : 320px;
  position        : absolute;
  right           : 0;
  background-color: #fff;
  top             : 65px;
  box-shadow      : 0 10px 20px rgba(0, 0, 0, 0.3);
  font-weight     : 400;
}

.search-mobile {
  border-top: 2px solid #ccc;
  padding   : 10px 5px 30px 25px;
}

.search-mobile .input-search {
  border       : none;
  width        : 90%;
  padding-right: 10px;
}

.navigation-mobile .ant-menu-submenu-title .ant-menu-title-content a {
  text-transform: uppercase;
  font-size     : 14px;
  color         : rgb(36, 44, 66);
  font-weight   : 500;
}

.navigation-mobile .ant-menu-submenu:first-child .ant-menu-submenu-title .ant-menu-title-content a {
  color: #3275bb;
}

/* CUSTOM CSS SWIPER GALLERY HOMEPAGE */
.gallery-swiper .swiper-slide {
  height: 250px;
}

.gallery-swiper .swiper-slide .ant-image {
  width           : 100%;
  height          : 100%;
  padding         : 10px;
  background-color: rgba(0, 0, 0, 0.3);
}

.gallery-swiper .swiper-slide .ant-image img {
  height    : 100%;
  object-fit: cover;
}

.language-mobile {
  display: none;
}


.captcha-box {
  width                : 120px;
  background-color     : #333;
  color                : #fff;
  font-size            : 20px;
  line-height          : 1;
  padding              : 6px;
  text-align           : center;
  -webkit-user-select  : none;
  -webkit-touch-callout: none;
  -moz-user-select     : none;
  -ms-user-select      : none;
  user-select          : none;
}

.content-viewer p {
  font-size: 16px;
}

.flex-between {
  display        : flex;
  justify-content: space-between;
}

/* Course Carousel buttons style */
.course_carousel .swiper-button-prev,
.course_carousel .swiper-button-next {
  top: 40px;
}

.course_carousel .swiper-button-prev:after,
.course_carousel .swiper-button-next:after {
  font-size: 24px;
  color    : #333;
}

.group-title-index {
  display       : flex;
  flex-direction: column;
  align-items   : center;
}

.group-title-index>* {
  max-width: 80%;
}

.select-year {
  text-align: center;
  height    : 40px;
}

.select-year .ant-select-selector {
  height          : 100% !important;
  border-radius   : 0;
  background-color: #3275bb !important;
}

.select-year .ant-select-selector,
.select-year .ant-select-selector .ant-select-selection-item,
.select-year .ant-select-arrow {
  color: #fff;
}

.select-year .ant-select-selector .ant-select-selection-item {
  font-size  : 20px;
  line-height: 1.8;
}

.title-heading {
  text-transform: uppercase;
}

.title-lg {
  font-weight   : 500;
  font-size     : 28px;
  text-align    : center;
  padding       : 20px 0;
  margin        : 0;
  color         : #54813e;
  text-transform: uppercase;
}

.text-md {
  font-size  : 16px;
  line-height: 1.8;
}

.text-lg {
  font-size  : 18px;
  line-height: 1.8;
}

.post-content p {
  margin-top   : 0;
  margin-bottom: 20px;
  font-size    : 16px;
  line-height  : 1.8;
}

.wro .pricing .pricing-content {
  padding: 16px;
}

.wro .pricing .pricing-content .pricing-title {
  line-height: 1.2;
  font-size  : 24px;
  height     : 60px;
}

.wro-categories {
  margin-top: 50px;
}

.wro-categories>* {
  flex: 1;
}

.wro-categories>* .btn {
  width: 100%;
}

.wro-categories .btn.active {
  background-color: #54813e;
}

.table-competition th,
.table-competition td {
  border         : 1px solid black;
  border-collapse: collapse;
}

/* CSS for ant icon */
.anticon-close {
  color: #ff4d4f !important;
}

.anticon-check {
  color: #1ba75a !important;
}

.anticon-minus {
  color: purple !important;
}

.Attendance-status {
  cursor: pointer;
}

.ant-dropdown-menu-title-content {
  margin-left: 5px;
  padding-top: 10px;
}

/* style modal register */


.form-register {
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;
  margin         : 60px 0;
}

.form-register .intro-title {
  text-transform: uppercase;
  font-size     : 32px;
  font-weight   : 600;
  text-align    : center;
  margin-bottom : 40px;
}

.form-register .btn-register {
  background-color: #3275bb;
}

.form-register .h-500 {
  height: 700px;
}

.course-des .course-des-content img {
  width: 100% !important;
}

/* CUSTOM CSS FOR SELECT DROPDOWN */
.menu-dropdown {
  width: 240px !important;
}

.col-md-9 .edugate-layout-1 .edugate-content .title {
  overflow: unset !important;
}

.edugate-layout-1 .edugate-content .title {
  overflow: unset !important;
}

.edugate-layout-1 .edugate-content .btn {
  bottom: 12px !important;
}

.col-md-9 .edugate-layout-1 .edugate-content {
  padding: 20px 20px 50px 25px;
}

.col-md-9 .edugate-layout-1 .edugate-content .info-more {
  margin-bottom: 20px !important;
}

@media screen and (max-width: 1025px) {

  .col-md-9 .edugate-layout-1 .edugate-content {
    padding-bottom: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .slider-banner {
    height    : 400px;
    min-height: 400px;
  }

  .slider-banner .slider-item {
    height    : 400px;
    min-height: 400px;
  }

  .language-mobile {
    display: unset;
  }

  header .header-topbar .group-sign-in .login:after {
    margin-right: unset;
    padding-left: unset;
    content     : "";
  }
}

@media only screen and (max-width: 600px) {

  .col-md-9 .edugate-layout-1 .edugate-content,
  .col-md-9 .edugate-layout-1 .edugate-image {
    padding: 0 10px;
  }

  .col-md-9 {
    padding: 0 10px;
  }

  :where(.css-dev-only-do-not-override-15rg2km).ant-list .ant-list-pagination-align-end {
    text-align: center;
  }

}



@media only screen and (max-width: 576px) {

  /* SET FULL WIDTH ON MOBILE */
  .fw-mb {
    width: 100% !important;
  }

  input {
    font-size: 16px !important;
  }

  .ant-form .ant-form-item {
    margin-bottom: 10px !important;
  }

  .course_carousel .swiper-button-prev:after,
  .course_carousel .swiper-button-next:after {
    font-size: 24px;
  }

  .page-title {
    height: 220px;
  }

  .page-title .captions {
    font-size: 32px;
  }

  .progress-bar-number {
    margin-bottom: 30px;
  }

  .wro-introduction-row-2 {
    flex-direction: column-reverse;
  }

  .wro .pricing .pricing-content .pricing-title {
    height: unset !important;
  }
}


@media only screen and (min-width: 576px) {

  .course_carousel .swiper-button-prev:after,
  .course_carousel .swiper-button-next:after {
    font-size: 36px;
  }
}

@media only screen and (max-width: 577px) {
  .ant-modal-body .ant-form-item-label {
    padding-bottom: 0px;
  }

  .course-des .course-des-content img {
    height: 75% !important;
  }

  .edugate-layout-1 .edugate-content button {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 380px) {
  .navigation-mobile {
    top: 45px;
  }
}